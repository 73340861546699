import React, { Fragment, useCallback } from 'react';
import classNames from 'classnames';
import update from 'immutability-helper';
import moment from 'moment';
import Measure from 'react-measure';
import {
	CTX_CLOSE_SEARCH
	, CTX_MANUAL
	, CTX_MY
	, CTX_MY_SEARCH
	, CTX_NEW
	, CTX_NEW_SEARCH
	, CTX_REVIEW
	, CTX_SEARCH
	, CTX_SEARCH_ANSWER
	, STATUS_ACQUIRED
	, STATUS_HANDLED
	, STATUS_OWNED
	, STATUS_REVIEW
	, STATUS_NEW
	, ACQUIRE_STATUS_MESSAGES
	, TITLE_ATTACHED_FILES
	, TITLE_COLLABORATE_FILES
	, TXT_CHECK_TAKEN_STATUS
	, TXT_ERRAND_ALREADY_HANDLED_BY
	, TXT_ERRAND_FROM_SEARCH_ANSWER
	, TXT_ERRAND_OPEN_FROM_FOLDER
	, TXT_ERRAND_OWNED_BY_WHO
	, TXT_ERRAND_UNHANDLED
	, TXT_MANUAL_ERRAND
	, TXT_OWNED_BACK_TO_INBOX
	, TXT_OWNED_PICK_ANOTHER
	, TXT_OWNER_TAKES_ERRAND
	, TXT_PENDING_REVIEW_ERRAND
	, TXT_REVIEW_ERRAND_OWNED_BY_WHO
	, TXT_OPEN_NEW_ERRAND
	, AET_LINKED
} from '../../common/v5/constants';
import { CHAT_MAX_EXTERNAL_DATA_HEADER_DISPLAY } from '../../redux/constants/constants';
import { I } from '../../common/v5/config.js';
import { Spinner } from '../../reactcomponents/Spinner';
import Anchor from '../../reactcomponents/Anchor';
import {
	AreaDropdown
	, default as Dropdown
} from '../../reactcomponents/Dropdown';
import Calendar from '../../reactcomponents/DatePicker';
import { ErrandDisplayID } from '../../reactcomponents/HistoriesAndOthers';
import { IPAddressCtnr } from '../../containers/chat';
import ErrandTags, { DisplayOnlyTags } from './ErrandTags';
import { TableIconicButton as Button } from '../../reactcomponents/Form';
import CFlag from '../../reactcomponents/CFlag';
import Feature from '../../components/v5/Feature';
import { RPLY_TAB_ATTACHMENT } from '../../common/v5/constants';

const showAttachmentBoxTxt = I("Show attachment box")
	, hideAttachmentBoxTxt = I("Hide attachment box")
	;
const AttachmentSize = ({ children, notReady, readOnly, viewSize }) => {
	if (notReady) {
		return <Spinner />;
	}
	if (readOnly) {
		children = null;
	}
	return <span className='attachment-size'>{viewSize}{children}</span>;
};

const ViewAttachmentBox = ({
	notReady
	, viewSize
	, readOnly
	, totalSize
	, show
	, type
	, onClick
	, showReplyBox
	, selectedReplyTab
}) => {
	let dom, title;
	const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");
	if (!readOnly && totalSize > 0) {
		if (show) {
			title = hideAttachmentBoxTxt;
		} else {
			title = showAttachmentBoxTxt;
		}
		if (type === 'text') {
			let txt;
			if (show) {
				txt = I('Hide files');
			} else {
				txt = I('View files');
			}
			dom = (
				<Anchor
					onClick={onClick}
					title={title}
					data-qa-id={"QA_showErrandAttachment"}
				>
					{txt}
				</Anchor>
			);
		} else {
			let icon;
			if(cflagAttachment && !showReplyBox || cflagAttachment && selectedReplyTab !== RPLY_TAB_ATTACHMENT ) { //straight show open
				icon = "icon-eye";
			} else {
				if (show) {
					// icon = "fa fa-minus";
					icon = cflagAttachment ? "icon-eye-blocked" : "fa fa-minus-circle fa-md";
				} else {
					// icon = "fa fa-plus";
					icon = cflagAttachment ? "icon-eye" : "fa fa-plus-circle fa-md";
				}
			}
			dom = (
				<i
					className={icon}
					onClick={onClick}
					title={title}
					data-qa-id={"QA_showErrandAttachment"}
				/>
			);
		}
	} else {
		dom = null;
	}
	return (
		<AttachmentSize
			notReady={notReady}
			readOnly={readOnly}
			viewSize={totalSize}
		>
			&nbsp;{dom}
		</AttachmentSize>
	);
};

const defaultStatusResult = {
	className: "owned"
	, icon: ""
	, msg: TXT_OWNER_TAKES_ERRAND
	, status: STATUS_HANDLED
};

function youOrOwner(acquiredData, activeUserId) {
	return acquiredData.owner.id == activeUserId ? I("You") : acquiredData.owner.name;
}

function statusNewAndNewSearchAndSearch({ errand, acquiredData, activeUserId }) {
	if (errand.closed || acquiredData.data.deleted) {
		return {
			status: STATUS_HANDLED
			, msg: TXT_ERRAND_ALREADY_HANDLED_BY.format(youOrOwner(
				acquiredData
				, activeUserId
			))
			, className: "handled" // red
			, icon: "icon-caution"
		};
	} else if (acquiredData.acquired) {
		return {
			status: STATUS_OWNED
			, msg: TXT_OWNER_TAKES_ERRAND
			, className: "owned" // green
		};
	}
	return {
		status: STATUS_NEW
		, msg: TXT_OPEN_NEW_ERRAND
		, className: "new"
	};
}

function statusMyAndMySearchAndCloseSearch({
	errand
	, acquiredData
	, activeUserId
}) {
	const name = youOrOwner(acquiredData, activeUserId);
	if (errand.closed || acquiredData.data.deleted) {
		return {
			status: STATUS_HANDLED
			, msg: TXT_ERRAND_ALREADY_HANDLED_BY.format(name)
			, className: "handled"
			, icon: "icon-caution"
		};
	}
	const owned = acquiredData.owner.id == activeUserId;
	return {
		status: owned ? STATUS_OWNED : STATUS_ACQUIRED
		, msg: TXT_ERRAND_OPEN_FROM_FOLDER.format(I("My Errands"), name)
		, className: owned ? "owned" : "acquired"
		, icon: owned ? "" : "icon-caution"
	};
}

const statusSearchAnswer = () => ({
	msg: TXT_ERRAND_FROM_SEARCH_ANSWER
	, className: "acquired"
	, icon: "icon-caution"
});

function statusManual({ errand, acquiredData, activeUserId }) {
	let msg;
	if (errand.service == Workflow.Errand.SERVICE_VOICE) {
		msg = TXT_ERRAND_OWNED_BY_WHO.format(youOrOwner(
			acquiredData
			, activeUserId
		));
	} else {
		msg = TXT_MANUAL_ERRAND;
	}
	return {
		status: STATUS_OWNED
		, msg
		, className: "owned"
		, icon: ""
	};
}

function statusReview(props) {
	const { errand, acquiredData, activeUserId } = props;
	if (errand.pendingReview) {
		return {
			status: STATUS_REVIEW
			, msg: TXT_REVIEW_ERRAND_OWNED_BY_WHO.replace(
				"{AGENT_NAME}"
				, youOrOwner(acquiredData, activeUserId)
			)
		};
	}
	return statusMyAndMySearchAndCloseSearch(props);
}

const statusHandlerMap = {
	[CTX_MANUAL]: statusManual
	, [CTX_MY]: statusMyAndMySearchAndCloseSearch
	, [CTX_MY_SEARCH]: statusMyAndMySearchAndCloseSearch
	, [CTX_NEW]: statusNewAndNewSearchAndSearch
	, [CTX_NEW_SEARCH]: statusNewAndNewSearchAndSearch
	, [CTX_CLOSE_SEARCH]: statusMyAndMySearchAndCloseSearch
	, [CTX_REVIEW]: statusReview
	, [CTX_SEARCH]: statusNewAndNewSearchAndSearch
	, [CTX_SEARCH_ANSWER]: statusSearchAnswer
};

function getStatusHandlerResult({ currentContext, ...props }) {
	const handler = statusHandlerMap[currentContext];
	if (typeof handler === "undefined") {
		return defaultStatusResult;
	}
	return update(defaultStatusResult, {$merge: handler(props)});
}

class AcquireStatus extends React.PureComponent {
	constructor(props) {
		super(props);
		this.returnToPrevious = this.returnToPrevious.bind(this);
		this.returnToInbox = this.returnToInbox.bind(this);
	}
	returnToPrevious() {
	}
	returnToInbox() {
		this.props.onReturnToInbox(this.props.errand.id);
	}
	render() {
		const { props } = this;
		if (!props.acquiredData) {
			return <Spinner />;
		}
		const { className, icon, msg, status } = getStatusHandlerResult(props);
		return (
			<Anchor
				className={className}
				data-tooltip
				data-qa-id="QA_acquired_status"
			>
				&nbsp;{status}
				<div
					className={classNames(
						"tooltip-content status"
						, className
					)}
				>
					{className !== "owned"
						&& <div className="icon">
							<i className={icon} />
						</div>
					}
					<div className="content">
						{msg}
					</div>
				</div>
			</Anchor>
		);
	}
}

const Block = ({ className, children, innerRef }) => <td ref={innerRef} className={className}>{children}</td>;

const TitleBlock = ({ children, title, className, ...props }) => (
	<Block className={className}>
		<span data-qa-id={props["data-qa-id"]} className="label">
			{title}
		</span>
		{children}
	</Block>
);

// NOTE: check also SpaceSpan if change.
export const SpaceSpanWithExtra = ({ data, dataTitle, extra, className, ...props }) => {
	if (!extra) {
		extra = "";
	}
	return (
		<span title={dataTitle}
			className={className}
			data-qa-id={props["data-qa-id"]}>&nbsp;{data}{extra}
		</span>
	);
};

// NOTE: check also SpaceSpanWithExtra if change.
const SpaceSpan = ({ data, dataTitle, className }) => (
	<span title={dataTitle} className={className}>&nbsp;{data}</span>
);

const Span = SpaceSpan;

const Label = ({ title, data, dataTitle, className, emoji, ...props }) => (
	<TitleBlock title={title} data-qa-id={props["data-qa-id"]} className={className}>
		{ emoji ?
			<MoodEmoji emotion={emoji}/> : ""
		}
		<Span data={data} dataTitle={dataTitle}/>
	</TitleBlock>
);

const LabelDate = ({ title, ...props}) => <Label {...props} title={title} />;

const CopyTo = props => <Label {...props} title={I('COPY TO:')} />;

export const Channel = ({ data, dataTitle, extra, className, ...props }) => {
	const qaID = props["data-qa-id"];
	return (
		<TitleBlock data-qa-id={qaID}>
			<SpaceSpanWithExtra
				data-qa-id={qaID+"_data"}
				data={data}
				dataTitle={dataTitle}
				extra={extra}
				className={className}
			/>
		</TitleBlock>
	);
};

const Status = props => <Label {...props} className="errand-status" title={I('STATUS:')} />;

export const Errand = props => <Label {...props} />;

const ToOrFrom = ({ manual, ...props }) => (
	<Label {...props} title={manual ? I('FROM:') : I('TO:')} />
);

const MoodEmoji = ({emotion}) => {
	const renderEmoji = () => {
		switch (emotion) {
			case 'happy':
				return '😃';
			case 'sad':
				return '😞';
			case 'neutral':
				return '😐';
			default:
				return '😐';
		}
	}
	return (
		<span>{renderEmoji()}</span>
	);
};


const TextLabel = ({ label, dataTitle, ...props }) => (
	<Fragment>
		<Label {...props} title={label} dataTitle={dataTitle} />
	</Fragment>
);

const areaNested = {key: "Areas"}
	, areaIDFields = {id: "Id", name: "Name"};

const Area = ({
	data
	, id
	, readOnly
	, selected
	, show
	, onSelect
	, onToggle
	, forwardAreaInOrg
	, disabled
	, ...props
}) => {
	return (<Block className="errand-area-change">
		<AreaDropdown
			title={I('AREA:')}
			readOnly={readOnly}
			show={show}
			id={id}
			data={data}
			selected={selected}
			forwardAreaInOrg={forwardAreaInOrg}
			forwardAreaInOrgKey={"Areas"}
			nested={forwardAreaInOrg? null : areaNested}
			idFields={areaIDFields}
			textNoItemSelected={I("No Area selected")}
			onToggle={onToggle}
			onSelect={onSelect}
			disabled={disabled}
		/>
	</Block>);
};

const ChatArea = props => <Label {...props} title={I('AREA:')} />;

const DoneDate = ({ data, innerRef, onChange, readOnly, ...props }) => {
	let selectedDate;
	if (data !== "") {
		selectedDate = moment(data, "YYYY/MM/DD");
	} else {
		selectedDate = null;
	}
	return (
		<Block className="errand-due-date" innerRef={innerRef}>
			<span>{I('DUE DATE:')}
				<Calendar
					readOnly={readOnly}
					selected={selectedDate}
					onChange={onChange}
					defaultValue={I("No Date")}
				/>
			</span>
		</Block>
	);
};

const ReturnPath = props => <Label {...props} title={I('RETURN PATH:')} />;

const Device = props => <Label {...props} title={I('DEVICE:')} />;

const Owner = props => <Label {...props} title={I('AGENT:')} />;

const Attachment = ({
	children
	, show
	, title
	, viewSize
	, totalSize
	, notReady
	, onClick
	, readOnly
	, ...props
}) => (
	<Block className="errand-attachment-dload">
		<span
			className='view-attachment-box'
			data-qa-id={props["data-qa-id"]}
		>
			{title + ':'}&nbsp;
			<ViewAttachmentBox
				type='icon'
				readOnly={readOnly}
				show={show}
				viewSize={viewSize}
				totalSize={totalSize}
				notReady={notReady}
				onClick={onClick}
				{...props}
			/>
		</span>
	</Block>
);

function capitaliseWord(str) {
	var out = "";
	if ((typeof str === 'string' || str instanceof String) && str.length >= 1) {
		out += str.charAt(0).toUpperCase();
		if (str.length >= 2) {
			out += str.slice(1);
		}
	}
	return out;
}

function parseJson(text){
	try{
		return JSON.parse(text);
	} catch(error){
		return null;
	}
}

function isManual(manualMap, type) {
	if (typeof manualMap[type] !== "undefined") {
		return true;
	}
	return false;
}

class HeaderInfo extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleToggleState = this.handleToggleState.bind(this);
		this.handleErrandStateChange = this.handleErrandStateChange.bind(this);
		this.handleDueDateChange = this.handleDueDateChange.bind(this);
		this.getStyleTheme = this.getStyleTheme.bind(this);

		this.state = {
			showState: false,
			dimensions: {
				width: -1,
				height: -1,
			},
			doneDateSize: {
				width: -1,
				height: -1,
			},
			doneSubSize: {
				width: -1,
				height: -1,
			}
		}
	}
	handleToggleArea(type, action) {
		this.props.onToggleArea(!action);
	}
	handleToggleState(e) {
		this.setState({showState: !this.state.showState});
	}
	handleErrandStateChange(id) {
		if(this.props.errand &&
			this.props.errand.service !== Workflow.Errand.SERVICE_EMAIL &&
			this.props.errand.service !== Workflow.Errand.SERVICE_MANUAL){
			return;
		}
		let eState = this.props.errandStates[id];
		if(typeof eState !== 'undefined'){
			this.props.onChangeState(parseInt(id), eState.updateSender,
				eState.name);
		}
	}
	handleDueDateChange(date) {
		let doneDate = "";
		if (date) {
			doneDate = date.format("YYYY/MM/DD");
		}
		this.props.onChangeDoneDate(doneDate);
	}
	getStyleTheme(style){
		let styleClass = "";
		if(style.themeWarning == "System Warning Theme"){
			styleClass = "fas fa-circle expired";
		}else if(style.themeWarning == "System Warning Alert Theme"){
			styleClass = "fas fa-circle warning";
		}
		return <td className="warning-circle">
			<div>
				<i className={styleClass}></i>
			</div>
		</td>
	}
	handleDoneSubSize = (contentRect) => {
		this.setState({
			doneSubSize: contentRect.bounds,
		})
	}
	handleDoneDateSize = (contentRect) => {
		this.setState({
			doneDateSize: contentRect.bounds,
		})
	}
	handleDimensionSize = (contentRect) => {
		this.setState({
			dimensions: contentRect.bounds,
		})
	}
	handleSwitchTab = () => {
		this.props.onSwitchAcquire(AET_LINKED);
		this.props.onToggleReplyBoxErrandMenu(true);
	}
	render() {
		const {
				attachment
				, errand: data
				, isCollaboration
				, isOtherContact
				, manualServices
				, owner
				, nameOncontactCard
				, showErrandState
				, errandStates
				, service
				, subject
				, style
				, onToggleReplyBoxErrandMenu
				, myErrandsNorm
				, ...p
			} = this.props
			;
		const { width, height } = this.state.dimensions;
		let secondRowSecondCol = null
			, secondRowThirdCol
			, TagsComponent
			;
		let thirdRowThirdCol = [];
		let hasExternalJsonData = false, colspanFull = 3;
		let status;
		if (!p.isChat) {
			status = <AcquireStatus
						errand={data}
						acquiredData={p.acquiredData}
						currentContext={p.currentContext}
						currentAgentStatus={p.currentAgentStatus}
						activeUserId={p.activeUserId}
						autoPickupFeature={p.autoPickupFeature}
						onReturnToInbox={p.onReturnToInbox}
					/>;
		} else if (p.chat.dead) {
			status = I("Offline");
		} else {
			status = p.chat.ClientOnline ? I("Online") : I("Away");
		}
		let errandWarning = this.getStyleTheme(style);
		let clientDeviceInfo, clientDeviceTitle, chatDeviceInfo = null;
		if (p.isChat && p.chat && p.chat.ClientDeviceInfo) {
			let deviceInfo = JSON.parse(p.chat.ClientDeviceInfo);
			const deviceVendorWithSlash = deviceInfo.deviceVendor ? "/"+deviceInfo.deviceVendor : "";
			if (deviceInfo.deviceType) {
				clientDeviceInfo = capitaliseWord(deviceInfo.deviceType) +
						 (deviceInfo.deviceModel ? " ("+deviceInfo.deviceModel+deviceVendorWithSlash+")" : "");
			} else if (deviceInfo.deviceModel) {
				clientDeviceInfo = capitaliseWord(deviceInfo.deviceModel) + deviceVendorWithSlash;
			} else if (deviceInfo.isMobile) {
				clientDeviceInfo = I("Mobile Device")
			} else if (deviceInfo.osName) {
				clientDeviceInfo = I("Desktop") + " " + deviceInfo.osName + (deviceInfo.osVersion ? " ("+deviceInfo.osVersion+")" : "");
			} else {
				clientDeviceInfo = I("Unknown Device")
			}
			if (deviceInfo.osName) {
				clientDeviceInfo += " " + deviceInfo.osName + " " + (deviceInfo.osVersion ? " ("+deviceInfo.osVersion+")" : "");
			}
			if (deviceInfo.browserName) {
				clientDeviceInfo += " / " + deviceInfo.browserName + " (" + deviceInfo.browserVersion + ")";
			}
			clientDeviceTitle = (deviceInfo.isMobile ? I("Mobile Device") : I("Non-Mobile Device")) + "\n" +
								(deviceInfo.browserName ? "Browser: " + deviceInfo.browserName + " (" + deviceInfo.browserVersion + ")\n" : "") +
								(deviceInfo.osName ? "OS: " + deviceInfo.osName + (deviceInfo.osVersion ? " ("+deviceInfo.osVersion+")\n" : "") : "");
			if (deviceInfo.deviceModel || deviceInfo.deviceVendor || deviceInfo.deviceType) {
				clientDeviceTitle += "Device Model: " + (deviceInfo.deviceModel ? deviceInfo.deviceModel : I("N/A")) +
								", Vendor: " + (deviceInfo.deviceVendor ? deviceInfo.deviceVendor : I("N/A")) +
								", Type: " + (deviceInfo.deviceType ? deviceInfo.deviceType : I("N/A"))
			}
			if (!deviceInfo.browserName) {
				clientDeviceTitle += deviceInfo.ua;
			}
		}
		let chatSource = "", dateTitle = I('DATE:'), doneDateEnabled = false;
		if(p.doneDateFeature && p.errandAreaData && p.errandAreaData.due_date_selected && !p.isChat){
			doneDateEnabled = true;
		}
		if (p.isChat) {
			let deviceColSpan;
			if (isCollaboration) {
				secondRowSecondCol = attachment;
			} else {
				deviceColSpan = "2";
			}
			chatDeviceInfo = <td colSpan={deviceColSpan}>
								<span className="label">
									{I('DEVICE:')}
								</span>
								<span data-qa-id="QA_errandInfo_chatClientIP" title={clientDeviceTitle}>{clientDeviceInfo}</span>
							</td>;
			dateTitle = I("STARTED:");
			if (data.sourceId === p.Workflow.Errand.SERVICE_FACEBOOK
				|| data.sourceId === p.Workflow.Errand.SERVICE_TWITTER
				|| data.sourceId === p.Workflow.Errand.SERVICE_LINE
				|| data.sourceId === p.Workflow.Errand.SERVICE_WHATSAPP
				|| data.sourceId === p.Workflow.Errand.SERVICE_TELEGRAM
				|| data.sourceId === p.Workflow.Errand.SERVICE_VIBER
				|| data.sourceId === p.Workflow.Errand.SERVICE_INSTAGRAM
				){
				chatSource = <b> ( {L(data.sourceName)} )</b>;
				secondRowThirdCol = null;
				chatDeviceInfo = null;
				if(data.sourceId === p.Workflow.Errand.SERVICE_FACEBOOK){
					let index = p.chat.messages.length - 1;
					let lastMessage = p.chat.messages[index];
					status = I("Offline")
					if(typeof lastMessage.agent !== 'undefined'){
						if(lastMessage.watermark === "read"){
							//let time = moment.unix(lastMessage.sent);
							//let currTime = moment.now();
							//let diff = currTime - time;
							//if(Math.round(diff / 60000) < 10){
							status = I("Online")
							//}
						}
					}
				}
			} else {
				secondRowThirdCol = <Block><IPAddressCtnr chat={p.chat}/></Block>;
			}
			if(p.chat.ExternalData != "" ){
				let jsonObj = parseJson(p.chat.ExternalData);
				if (jsonObj != null) {
					let loop = 0;
					for(var prop in jsonObj){
						if (Object.prototype.hasOwnProperty.call(jsonObj,
							prop)) {
							if(prop != "hidden"){
								thirdRowThirdCol.push(<td
									title ={jsonObj[prop].value}
									key={"QA_errandInfo_"+
										loop + jsonObj[prop].name}>
									<span>{jsonObj[prop].name+":"}&nbsp;</span>
									<span>{jsonObj[prop].value}</span></td>);
								loop++;
								hasExternalJsonData = true;
							}
						}
						if(loop >= CHAT_MAX_EXTERNAL_DATA_HEADER_DISPLAY){
						break;
						}
					}
				}
			}
		} else if (isOtherContact) {
			const title = owner.name + " (" + owner.id + ")";
			secondRowSecondCol = <Owner data={owner.name} dataTitle={title} />;
		} else {
			secondRowSecondCol = null;
		}
		if (isOtherContact) {
			TagsComponent = DisplayOnlyTags;
		} else if (p.tags) {
			TagsComponent = ErrandTags;
		}
		let frmAddress = null;
		if(data.fromAddress){
			frmAddress = (' (' + data.fromAddress + ')')
		}
		let showReturnPath = false;
		if(p.showErrandReturnPath == true &&
			data.service === p.Workflow.Errand.SERVICE_EMAIL){
				showReturnPath = true;
		}
		let subjectWidth = this.state.doneSubSize.width;

		//For Errand Auto Classification (AI) for mood and language
		let errandMood = "", errandMoodScore = "", errandEmoji = "", errandLanguage = "", errandLanguageScore = "";
		if(data && data.autoClassification) {
			const moodAndLanguageClassify = {};
			const validJSONClassify = JSON.parse(data.autoClassification);
			for(var prop in validJSONClassify){
				if (Object.prototype.hasOwnProperty.call(validJSONClassify,
					prop)) {
						//This will be displayed as "Mood" and "Language" in the UI
						if(prop == "language" || prop == "language-score" || prop == "sentiment" || prop == "sentiment-score"){
							moodAndLanguageClassify[prop] = validJSONClassify[prop];
						}
				}
			}
			if(moodAndLanguageClassify["sentiment"] == "positive"){
				errandMood = I("Positive");
				errandEmoji = "happy";
			} else if(moodAndLanguageClassify["sentiment"] == "negative"){
				errandMood = I("Negative");
				errandEmoji = "sad";
			} else {
				errandMood = I("Neutral");
				errandEmoji = "neutral";
			}
			errandMoodScore = moodAndLanguageClassify["sentiment-score"];
			errandMoodScore = " (" + Math.round(errandMoodScore * 100) + "%)";
			if(moodAndLanguageClassify["language"]){
				errandLanguage = moodAndLanguageClassify["language"];
				errandLanguageScore = moodAndLanguageClassify["language-score"];
				errandLanguageScore = " (" + Math.round(errandLanguageScore * 100) + "%)";
			}
		}
		const hasLinkedErrands = (myErrandsNorm && myErrandsNorm[data.id] && myErrandsNorm[data.id].linkedId > 0) ? true : false;

		let newUI_errandBoxInfo = (measureRef) =>  <div className={classNames("errand-box-info-text v5", {chat: p.isChat})} ref={measureRef}>
								<div className="info-section">
									<Measure bounds onResize={this.handleDoneSubSize}>
										{({ measureRef }) => (
											<div className="errand-from dynamic" ref={measureRef}>
												<div className="dynamic-inner-wrap">
													<ErrandDisplayID
														className='errand-id'
														data-qa-id='QA_errandInfo_errandId'
														displayId={p.displayId}
														title={subject}
													/>
													{hasLinkedErrands &&
														<Feature tag="link-errand">
															{p.toggleableErrandLink ?
																<Button
																type='button'
																className={classNames("btn linked-errands")}
																iconClass='icon-link'
																title={I('Linked Errand')}
																onClick={this.handleSwitchTab}
																hide={false}
															/>
															:
															<i className='linked-errands icon-link' title={I('Linked Errand')}></i>
															}
														</Feature>
													}
													<span className="errand-subject" title={subject} data-qa-id="QA_errandInfo_subject">
														{subject}
													</span>
												</div>
											</div>
										)}
									</Measure>
									{/*2nd part for tags */}
									<div className="tag-section" style={{width: isOtherContact ? "100%" : (width - subjectWidth)}}>
										<table>
											<tbody>
												{hasExternalJsonData &&
													<tr>
														{TagsComponent &&
														<td key={"QA_errandInfo_tagsChat_td"}>
															<TagsComponent
																readOnly={isOtherContact}
																me={p.me+'etags'}
																title={I('TAGS:')}
																tags={p.tags}
																id={"QA_errandInfo_tags"}
																allTags={p.allTags}
																chat={p.chat}
																eid={p.eid}
																onAddTag={p.onAddTag}
																height='400px'
																onDeleteTag={p.onDeleteTag}
																onToggleTagsSelection={p.onToggleTagsSelection}
																showTagsSelection={p.ui.showTags}
																selectedTags={p.selectedTags}
															/>
														</td>
														}
														{thirdRowThirdCol}
													</tr>
												}
												<tr>
													<td colSpan={doneDateEnabled ? 2 : colspanFull} key={"QA_errandInfo_tags_td"} style={{overflow: "visible"}}>
													{!hasExternalJsonData && TagsComponent &&
														<TagsComponent
															outerWidth={(width - subjectWidth)}
															showMore={isOtherContact ? false : true}
															readOnly={isOtherContact || data.closed}
															me={p.me+'etags'}
															title={I('TAGS:')}
															tags={p.tags}
															id={"QA_errandInfo_tags"}
															allTags={p.allTags}
															chat={p.chat}
															eid={p.eid}
															onAddTag={p.onAddTag}
															height='65vh'
															marginTop={'20px'}
															onDeleteTag={p.onDeleteTag}
															onToggleTagsSelection={p.onToggleTagsSelection}
															showTagsSelection={p.ui.showTags}
															selectedTags={p.selectedTags}
														/>
													}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								{/*table (flex type)*/}
								<table className="info-section-table">
									<tbody>
										<tr className="flex-row">
											<LabelDate
												title={dateTitle}
												data-qa-id="QA_errandInfo_date"
												data={data.date}
												dataTitle={data.date}
											/>
											{p.isChat || isOtherContact ? null
												: <ToOrFrom
													data-qa-id="QA_errandInfo_to"
													data={data.to}
													dataTitle={data.to}
													manual={isManual(
														manualServices
														, data.service
													)}
												/>
											}
											{p.isChat || isOtherContact ? null :
												<CopyTo
													data-qa-id="QA_errandInfo_cc"
													data={data.copy}
													dataTitle={data.copy}
												/>
											}
											{p.isChat
												? <ChatArea
													data-qa-id="QA_errandInfo_area"
													data={p.chat.errand.data.areaName}
												/>
												: <Area
													id="changeErrandArea"
													readOnly={isOtherContact}
													show={p.ui.showArea}
													data={p.areas}
													selected={p.currentArea}
													onToggle={this.handleToggleArea}
													onSelect={p.onChangeArea}
													forwardAreaInOrg={p.forwardAreaInOrg}
													disabled={p.errandActionWip || (cflag.IsActive("2024-01-31.CEN-2169.rework.postponed.errand") && p.isPostponed)}
												/>
											}
											<Status
												data-qa-id="QA_errandInfo_status"
												data={status}
											/>
											{p.isChat ? chatDeviceInfo : attachment}
											{secondRowSecondCol}
											{secondRowThirdCol}
											{showReturnPath &&
												<ReturnPath
												data-qa-id="QA_errandInfo_returnpath"
												data={data.returnPath}
												dataTitle={data.returnPath}
												/>
											}
											{doneDateEnabled &&
												<Measure bounds onResize={this.handleDoneDateSize}>
													{({ measureRef }) => (
														<DoneDate
															innerRef={measureRef}
															data={p.currentDueDate}
															onChange={this.handleDueDateChange}
															// readOnly={isOtherContact}
														/>
													)}
												</Measure>
											}
											{showErrandState && !p.isChat &&
												service != p.Workflow.Errand.SERVICE_VOICE &&
												<Block className="errand-area-change">
												<Dropdown
													id={"QA_changeErrandState"}
													title={I('STATE:')}
													className={"acquired"}
													show={this.state.showState}
													multiSelect={false}
													onToggle={this.handleToggleState}
													data={errandStates}
													onSelect={this.handleErrandStateChange}
													selected={data.state}
													readOnly={false}
													disabled={p.errandActionWip || (cflag.IsActive("2024-01-31.CEN-2169.rework.postponed.errand") && p.isPostponed)}
												/>
												</Block>
											}
											{errandWarning}
											{ errandMood != "" &&
												<TextLabel
													data-qa-id="QA_errandInfo_mood"
													label={I("MOOD:")}
													data={errandMood}
													dataTitle={errandMood + errandMoodScore}
													emoji={errandEmoji}
													className="errand-auto-classify"
												/>
											}
											{ errandLanguage != "" &&
												<TextLabel
													data-qa-id="QA_errandInfo_language"
													label={I("LANGUAGE:")}
													data={errandLanguage}
													dataTitle={errandLanguage + errandLanguageScore}
													className="errand-auto-classify"
												/>
											}
										</tr>
									</tbody>
								</table>
							</div>
		return (
			<Measure bounds onResize={this.handleDimensionSize}>
				{({ measureRef }) => newUI_errandBoxInfo(measureRef)}
			</Measure>
		);
	}
}

const ErrandBoxHeaderInfo = ({
	attachmentNotReady,
	attachmentSize,
	isChat,
	isCollaboration,
	isOtherContact,
	onHandleAttachment,
	onToggleAttachBox,
	showAttachment,
	showReplyBox,
	selectedReplyTab,
	toggleAttachBox,
	totalAttachmentsSize,
	// toggleableErrandLink,
	...props
}) => {
	const handleClick = useCallback(
		e => onHandleAttachment(e),
		[onHandleAttachment]
	)
	let attachment
	if (!isChat || isCollaboration) {
		let fileTitle
		if (!isCollaboration) {
			fileTitle = TITLE_ATTACHED_FILES;
		} else {
			fileTitle = TITLE_COLLABORATE_FILES;
		}
		attachment = (
			<Attachment
				data-qa-id="QA_errandInfo_attachment"
				notReady={attachmentNotReady}
				onClick={handleClick}
				readOnly={isOtherContact}
				show={showAttachment}
				title={fileTitle}
				totalSize={totalAttachmentsSize}
				viewSize={attachmentSize}
				showReplyBox={showReplyBox}
				selectedReplyTab={selectedReplyTab}
			/>
		)
	} else {
		attachment = null
	}
	return (
		<HeaderInfo
			// toggleableErrandLink={toggleableErrandLink}
			attachment={attachment}
			isChat={isChat}
			isCollaboration={isCollaboration}
			isOtherContact={isOtherContact}
			{...props}
		/>
	)
}

export default ErrandBoxHeaderInfo
