import React from 'react';
import PropTypes from 'prop-types';
import {
	branch
	, compose
	, mapProps
	, renameProp
	, renderComponent
	, withHandlers
	, withProps
} from 'recompose';
import classNames from 'classnames';
import SplitPane from 'react-split-pane';
import { I, L } from '../../common/v5/config.js';
import {
	getEmailOutParentheses
	, isValidEmail
	, getHotkeyChar
} from '../../common/v5/helpers.js';
import {
	CALL_STR_HOLD
	, SIP_REGISTER
	, SIP_CALL_CONNECTING
	, SIP_CALL_CONNECTED
	, SIP_CALL_IDLE
	, SIP_CONNECTED
	, SIP_DISCONNECTED
	, SIP_OUTGOING_CALL_CONNECTING
} from '../../common/v5/callConstants.js';
import CallRinger from '../../reactcomponents/CallRinger';
import { doNothing } from '../../reactcomponents/common';
import {
	withIdentity
	, withRandomMultiProps
} from '../../reactcomponents/hocsForTests';
import {
	AssistBarLabel
	, AssistBarItems
	, AssistBarOneItem
	, AssistColumn
	, PreviewAssist
	, ProtectAssist
	, SalutationAssist
	, SignatureAssist
	, TemplateAssist
} from '../../reactcomponents/Assist';
import OneReplyNav from '../../reactcomponents/OneReplyNav';
import {
	composeWithDisplayName
	, createWithSingleOptionHandler
	, withDisableableOnClick
	, withIdAttachedOnClick
	, withSingleAreaOptionDetect
	, withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import { BTTN_CLEAR } from '../../reactcomponents/DialPad';
import Ckeditor from '../v5/Ckeditor';
import { AreaDropdown } from '../../reactcomponents/Dropdown';
import ErrandTags from './ErrandTags';
import {
	Buttons,
	EditorFootnotes,
	FooterCheckboxes,
	TweetWarningMsg
} from './Footers';
import {
	ManualCallCreate
} from './ManualCallErrand';
import ReplyToolbar from './ReplyToolbar';
import {
	LabeledSelectBox
	, LabeledSelection
	, SelectBox
} from '../../reactcomponents/SelectBox';
import TagPicker, { ControlTagPicker } from './TagPicker';
import { CtrlKnowledgeBasePopup } from './KnowledgeBasePopup';
import { DialPadCtnr, SimpleDialPadCtnr } from '../../containers/call';
import {
	BTN_CLEAR,
	DEFAULT_ACCOUNT_TITLE,
	DEFAULT_SIMPLE_TOOLBAR,
	ME_CREATE,
	ME_START,
	ME_NOTE,
	ME_ST_IDLE,
	ME_ST_BUSY,
	ME_ST_CREATED,
	MP_CALL,
	MP_BASIC_CALL,
	MP_ERRAND,
	MP_NONE,
	OBJ_ID_NAME,
	OPT_CHANNELS,
	OPT_FIELDS_ID_VALUE,
	OPT_ME_CALL_CREATE_AS,
	OPT_ME_CREATE_AS,
	RECIPIENT_BCC,
	RECIPIENT_CC,
	RECIPIENT_TO,
	RPLY_MANUAL,
	RPLY_MANUAL_COMMENT,
	RPLY_ERRAND,
	RPLY_COMMENT,
	RPLY_QUESTION,
	RPLY_COLLABORATE,
	ERRAND_HOTKEYS,
	TOGGLE_REPLY,
	TOGGLE_COMMENT,
	TOGGLE_QUESTION,
	RC_EMAIL,
	RC_SMS,
	RC_TWITTER,
	RC_VOICE,
	TAG_ADD,
	TAG_DELETE,
	TAG_CLEAR,
	UNKNOWN_WORKFLOW_SERVICE_TYPE,
	UNSELECT,
	RC_LINE,
	TWITTERTWEETLIMIT,
	emptyArray,
	RC_WHATSAPP,
	USED_FOR_WHATSAPP_TEMPLATE,
	ICON_REPLY,
	MP_MINIMIZE
} from '../../common/v5/constants';
import { MANUAL_ERRAND_MAX_RECIPIENT } from '../../redux/constants/constants';
import Anchor from '../../reactcomponents/Anchor';
import {
	buttonStateSelector,
	isPreviewDisabledSelector,
	showPreviewSelector
} from '../../redux/selectors/errand';
import CFlag, { NoCFlag } from '../../reactcomponents/CFlag';
// import { ReplyEditorToolbar } from '../../views/v5/errand';
import Channel from '../../reactcomponents/Channel';
import ToolbarButtonIcon from '../../reactcomponents/ToolbarButtonIcon';
import { ModalPopup } from '../../reactcomponents/Modal.js';
import { KBErrandsModal } from '../../views/v5/knowledgebase/knowledgeBase';

const baseClassName = "manual errand popup"
	, mErdToolbarID = "ckev5meTop"
	, allowEscToClear = [BTTN_CLEAR]
	, styleSplitPane = {position: "unset", overflow: "visible", height: "unset"}
	, styleMaxHeight = {height: "100%"}
	, styleDisplayNone = {display: "none"}
	, FROM_TITLE = I('FROM: ')
	, TO_TITLE = I('TO: ')
	, NEW_TITLE = I('New')
	, recipientsMap = {
		[RECIPIENT_TO]: {
			className: ""
			, id: "errand-email-to"
			, selected: "to"
			, maxInput: MANUAL_ERRAND_MAX_RECIPIENT
		}
		, [RECIPIENT_CC]: {
			fieldName: I("CC: ")
			, id: "errand-email-cc"
			, selected: "cc"
		}
		, [RECIPIENT_BCC]: {
			fieldName: I("BCC: ")
			, id: "errand-email-bcc"
			, selected: "bcc"
		}
	}
	, fullEmailRecipientList = [
		RECIPIENT_CC
		, RECIPIENT_BCC
	]
	, TT_CREATE = 1 // TT mean tab type
	, TT_START = 2
	, TT_CALL = 3
	, TT_TRANSFER = 4
	, TT_SEND = 5
	, tabsMap = {
		[TT_CREATE]: {
			id: "create-errand"
			, rel: "create"
			, text: I("Create an errand")
			, type: ME_CREATE
		}
		, [TT_START]: {
			id: "start-errand"
			, rel: "start"
			, text: I("Send an errand")
			, type: ME_START
		}
		, [TT_CALL]: {
			id: "outbound-call"
			, rel: "outbound-call"
			, text: I("Outbound call")
			, icon: "icon-make-call"
		}
		, [TT_SEND]: {
			id: "send-errand"
			, rel: "start"
			, text: I("Send errands")
			, type: ME_START
		}
	}
	, errandTabs = [
		TT_CREATE
		, TT_START
	]
	, bulkSendTab = [
		TT_SEND
	]
	, createReplyOptions = [
		{key: RPLY_QUESTION, id: ME_CREATE}, {key: RPLY_COMMENT, id: ME_NOTE}
	]
	, startReplyOptions = [
		{key: RPLY_ERRAND, id: ME_START}, {key: RPLY_COMMENT, id: ME_NOTE}
	]
	, bulkSendOptions = [
		{key: RPLY_ERRAND, id: ME_START}
	]
	;
const SplitOrNot = ({ isCall, ...props }) => {
	let maxSize
		, minSize
		, resizerStyle
		, size
		;
	if (!isCall) {
		maxSize = "100%";
		size = "100%";
		resizerStyle = styleDisplayNone;
	} else {
		maxSize = 500;
		minSize = 250;
	}
	return (
		<SplitPane
			allowResize={isCall}
			split={props.mobile ? "horizontal" : "vertical"}
			defaultSize="58%"
			maxSize={maxSize}
			minSize={minSize}
			size={size}
			style={styleSplitPane}
			resizerStyle={resizerStyle}
			{...props}
		/>
	);
};

const Infos = ({selectedTab, lastSavedTimestamp, isModified}) => {
	return null; // TODO: remove this temporary hack
	return <div className="errand-infos">
			<span>This message was auto-saved 2017-02-29, 14:17 and has not been sent</span>
			<div className="errand-footer-edits">
				<a href="#" className="errand-file">
					{I('Attach file') + ' '}
					<i className="icon-attachment"></i>
				</a>
				<a href="#" className="errand-translate">
					<i className="icon-translate"></i>
					{I('Translation options')}
				</a>
			</div>
		</div>;
};

class CreatedAsBase extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
		this.handleSelect = this.handleSelect.bind(this);
		this.toggle = this.toggle.bind(this);
	}
	handleSelect(v) {
		this.props.onSelect(v);
		this.setState({show: false});
	}
	toggle(v) {
		this.setState({show: v});
	}
	render() {
		const { isCall, selectedTab, selected, visible
			, hideNewErrandMenu } = this.props;
		let options;
		if (isCall) {
			options = OPT_ME_CALL_CREATE_AS;
		} else {
			options = OPT_ME_CREATE_AS;
		}
		return (
			<div className="label-block">
				<SelectBox
					id="create-errand-type-select"
					top='-72px'
					maxHeight='176px'
					visible={visible}
					className="sidebar-nav-select"
					onToggleShow={this.toggle}
					selected={selected}
					onSelect={this.handleSelect}
					show={this.state.show}
					options={options}
					data-qa-id="create-errand-type-select"
				/>
			</div>
		);
	}
}

const CreatedAs = withUnmountWhenHidden(CreatedAsBase);

class Bttns extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleButtonClick = this.handleButtonClick.bind(this);
	}
	handleButtonClick(type, e) {
		let state = this.props.state;
		if(this.props.isCall) {
			state = this.props.callState;
		}
		if(type === BTN_CLEAR) {
			return this.props.onClear(e);
		}
		this.props.onSubmit(this.props.selectedTab,
			this.props.selectedCreation, state === ME_ST_CREATED,
			false, this.props.isBulkSend);
	}
	render() {
		const {
			buttonsState
			, disabled
			, isCall
			, onSelectCreation
			, selectedTab
			, selectedCreation
			, hideNewErrandMenu
		} = this.props;
		return (
			<Buttons
				className="errand-buttons"
				condition={buttonsState}
				onButtonClick={this.handleButtonClick}
				tag='button'
			>
				<CreatedAs
					hidden={selectedTab !== ME_CREATE}
					isCall={isCall}
					selectedTab={selectedTab}
					visible={!disabled}
					selected={selectedCreation}
					onSelect={onSelectCreation}
					hideNewErrandMenu={hideNewErrandMenu}

				/>
			</Buttons>
		);
	}
}

class Assist extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onAppendTemplate = this.onAppendTemplate.bind(this);
		this.handlePreviewClick = this.handlePreviewClick.bind(this);
	}
	handlePreviewClick() {
		this.props.onClickPreview(
			this.props.currentSelected
			, this.props.selectedCreation
		);
	}
	onAppendTemplate(id, ob) {
		const p = this.props;
		let tmplName = '['+ ob.Name +']';
		if(p.selectedChannel == RC_TWITTER) {
			tmplName = $('' + ob.Content).text()
		}
		if(p.selectedChannel  === RC_WHATSAPP ) {
			if( ob.UsedFor === USED_FOR_WHATSAPP_TEMPLATE ){
				p.onShowTemplateCustom(p.showWATemplatePopup, ob.Content, ob.Id);
			} else {
				p.appendTemplate(tmplName);
			}
		} else {
			p.appendTemplate(tmplName);
		}
	}
	render() {
		const {
				isCall
				, onOpenInsertProtectedContentReply
				, salutations
				, selectedChannel
				, selectedSal
				, selectedSig
				, selectedTab
				, setSalutation
				, setSignature
				, signatures
				, templates
				, isBulkSend
				, ...props
			} = this.props
			, hide = selectedTab !== ME_START || isCall
			;
		return (
			<AssistColumn className="field errand-assist">
				<AssistBarLabel />
				<AssistBarItems>
					<AssistBarOneItem hidden={hide}>
						<TemplateAssist
							data={templates}
							data-qa-id="manual-erd-template"
							id="manualErrand-temp-assist"
							onAppendItem={this.onAppendTemplate}
							direction="down"
						/>
					</AssistBarOneItem>
					<AssistBarOneItem hidden={hide}>
						<SalutationAssist
							data={salutations}
							data-qa-id="manual-erd-sal"
							selected={selectedSal}
							onSelectItem={setSalutation}
							direction="down"
						/>
					</AssistBarOneItem>
					<AssistBarOneItem hidden={hide}>
						<SignatureAssist
							data={signatures}
							data-qa-id="manual-erd-sig"
							selected={selectedSig}
							onSelectItem={setSignature}
							direction="down"
						/>
					</AssistBarOneItem>
					<AssistBarOneItem hidden={isCall || isBulkSend}>
						<PreviewAssist
							disabled={props.previewDisabled}
							onClick={this.handlePreviewClick}
						/>
					</AssistBarOneItem>
					<AssistBarOneItem
						hidden={hide || selectedChannel !== RC_EMAIL}
					>
						<ProtectAssist
							onClick={onOpenInsertProtectedContentReply}
						/>
					</AssistBarOneItem>
				</AssistBarItems>
			</AssistColumn>
		);
	}
}

const createSingleOptionHandler = fieldName => withHandlers({
	onSingleOption: ({ onSelect }) => item => onSelect(item[fieldName])
});

const keyAreas = {key: 'Areas'};

const AreaBase = props => (
	<AreaDropdown
		title={I('AREA:')}
		data-qa-id="manual-area-dd"
		nested={keyAreas}
		idFields={OBJ_ID_NAME}
		textNoItemSelected={I("select an area")}
		{...props}
	/>
);

export const Area = composeWithDisplayName(
	"Area"
	, createSingleOptionHandler("Id")
	, withSingleAreaOptionDetect
)(AreaBase);

const AreaBaseV2 = props => (
	<AreaDropdown
		data-qa-id="manual-area-dd"
		nested={keyAreas}
		idFields={OBJ_ID_NAME}
		textNoItemSelected={I("select an area")}
		{...props}
	/>
);

export const AreaV2 = composeWithDisplayName(
	"Area"
	, createSingleOptionHandler("Id")
	, withSingleAreaOptionDetect
)(AreaBaseV2);

export class ChannelDropdown extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showSelectChannel: false
		}
	}
	handleShowSelectChannel = (v) => {
		this.setState({showSelectChannel: v});
	}
	render() {
		const {
			noChannelSelection
			, allowChangeChannel
			, show
			, replyAs
			, selectedCollabChannel
			, collabOpts
			, options
			, onSelectChannel
			, showChannelOpt
			, selectedChannel
			, ...p
		} = this.props
		, hasChannel = !noChannelSelection
		;
		let channel;

		let allowCollab = (replyAs === RPLY_COLLABORATE) && (
			p.collabSlackEnabled || p.collabMSTeamEnabled || p.collabJiraEnabled || p.collabGoogleChatEnabled);
		if (hasChannel) {
			channel = options[selectedChannel];
		}
		let chnOptions = options
		let selectedChn = selectedChannel;
		if (allowCollab) {
			chnOptions = collabOpts;
			selectedChn = selectedCollabChannel;
		}

		let selectedChannelIcon = ICON_REPLY; //default icon
		if (typeof chnOptions[selectedChn] !== 'undefined') {
			selectedChannelIcon = chnOptions[selectedChn].icon || ICON_REPLY;
		}

		const iconNode = <i className={selectedChannelIcon}></i>;
		let title = p.selectedChannelAsIconTitle ? iconNode : p.title;
		return (
			<Channel
				hidden={!showChannelOpt || !hasChannel}
				myClass={classNames(
					'channel',
					{
						'collab-channel-select': allowCollab,
						'channel-select': !allowCollab
					}
				)}
				onSelect={onSelectChannel}
				onToggle={this.handleShowSelectChannel}
				options={chnOptions}
				readOnly={!allowChangeChannel &&
					replyAs !== RPLY_MANUAL &&
					!allowCollab
				}
				selected={selectedChn}
				show={this.state.showSelectChannel}
				title={title}
				ddTitle={p.ddTitle}
			/>
		)
	}
}

class Titles extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showTag: false,
			showArea: false
		};
		this.handleToggleKnowledgeBase = this.handleToggleKnowledgeBase.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.toggleTag = this.toggleTag.bind(this);
		this.toggleArea = this.toggleArea.bind(this);
		this.addTag = this.addTag.bind(this);
		this.delTag = this.delTag.bind(this);
		this.onSelectSignature = this.onSelectSignature.bind(this);
		this.onSelectSalutation = this.onSelectSalutation.bind(this);
	}
	handleSelectAll(v) {
	}
	toggleTag(v) {
		this.setState({showTag: v});
	}
	toggleArea(t, v) {
		this.setState({showArea: !v});
	}
	addTag(v) {
		this.props.onTagChange(TAG_ADD, v);
	}
	delTag(v) {
		this.props.onTagChange(TAG_DELETE, v);
	}
	handleToggleKnowledgeBase() {
		const p = this.props;
		if(p.selectedArea > 0){
			p.onToggleKnowledgeBase(!p.openKnowledgeBase);
		}
	}
	onSelectSignature(id) {
		if(this.props.defaultSig){
			if(this.props.selectedSig > 0 && id === 0){
				this.props.setSignature(this.props.selectedSig);
			}else{
				this.props.setSignature(id);
			}
			this.props.setSignatureDefault();
		}else{
			this.props.setSignature(id);
		}
	}
	onSelectSalutation(id) {
		if(this.props.defaultSal){
			if(this.props.selectedSal > 0 && id === 0){
			   this.props.setSalutation(this.props.selectedSal);
		   }else{
			   this.props.setSalutation(id);
		   }
		   this.props.setSalutationDefault();
	   }else{
		   this.props.setSalutation(id);
	   }
	}
	render() {
		const {
				isCall
				, onOpenInsertProtectedContentReply
				, selectedTab
				, isBulkSend
				, ...p
			} = this.props
			;
		return (
			<div className="errand-titles">
				<div className="single-row">
					<div className="field errand-info" hidden={isBulkSend}>
						<span className="label title-input">{I('ERRAND:')}</span>
						<span className="errand-number">{p.number}</span>
					</div>
					<div className="field errand-area">
						<Area
							show={this.state.showArea}
							data={p.areas}
							selected={p.selectedArea}
							notReady={!p.areas}
							onToggle={this.toggleArea}
							readOnly={p.disabled}
							onSelect={p.onAreaChange}
						/>
					</div>
					<div className="field errand-channel" hidden={isBulkSend}>
						<ChannelDropdown
							showChannelOpt={p.showChannelOpt}
							options={p.options}
							replyAs={p.replyAs}
							onSelectChannel={p.onSelectChannel}
							selectedChannel={p.selectedChannel}
						/>
					</div>
				</div>
				<div className="single-row">
					<ErrandTags
						me='meTags'
						// title={I('TAGS')}
						data-qa-id={"manual-add-tag"}
						tags={p.tags}
						outerWidth='800'
						onAddTag={this.addTag}
						height='60vh'
						eid={0}
						readOnly={p.disabled}
						onDeleteTag={this.delTag}
						onToggleTagsSelection={this.toggleTag}
						showTagsSelection={this.state.showTag}
						selectedTags={p.selectedTags}
						showMore={true}
						addPosition={"left"}
						marginTop={"25px"}
					/>
				</div>
			</div>
		);
	}
}

const withRecipient = Component => class extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleFailValidation = this.handleFailValidation.bind(this);
		this.handleOpenContactBook = this.handleOpenContactBook.bind(this);
	}
	handleFailValidation(type, inpt) {
		if (type === RC_EMAIL) {
			if (this.props.onFailValidation) {
				this.props.onFailValidation(type, inpt);
			}
		}
	}
	handleChange(inputs, name) {
		let filtereds = [];
		if(inputs && inputs.length > 0) {
			$.each(inputs, (i,v) => {
				let filtered;
				if (this.props.type === RC_EMAIL) {
					const email = getEmailOutParentheses(v);
					if (isValidEmail(email)) {
						filtered = email;
					} else {
						this.handleFailValidation(this.props.type, email);
						return;
					}
				} else {
					filtered = v;
				}
				filtereds.push({id: filtered, value: filtered});
			});
		}
		this.props.onChange(filtereds, name);
	}
	handleOpenContactBook() {
		this.props.onToggleContactBook(true, this.props.name)
	}
	render() {
		const { inputRef, inputValue, onChangeInput, ...p } = this.props;
		let inputText = inputValue;
		if(this.props.callType === MP_BASIC_CALL){
			inputText = "";
		}
		return (
			<div className={classNames("input form-1-2", p.className)}>
				<label htmlFor={p.id}>{p.fieldName}</label>
				<div className="tag-picker-input">
					<Component
						id={p.id}
						inputValue={inputText}
						ref={inputRef}
						type={p.type}
						name={p.name}
						maxInput={p.maxInput}
						options={p.options}
						fields={OPT_FIELDS_ID_VALUE}
						disabled={p.disabled}
						selected={p.selected}
						onChange={this.handleChange}
						onChangeInput={onChangeInput}
						onFailValidation={this.handleFailValidation}
						onShowContactBook={this.handleOpenContactBook}
					/>
				</div>
			</div>
		);
	}
}

const ControlToRecipient = withRecipient(ControlTagPicker);

const withRemoveProps = propsToBeRemoved => Component => ({ ...props }) => {
	$.each(propsToBeRemoved, (i, v) => {
		delete props[v];
	});
	return <Component {...props} />;
};

const Recipient = compose(
	withRemoveProps(["inputRef", "inputValue", "onChangeInput"])
	, withRecipient
)(TagPicker);

const ToRecipient = ({ isCall, ...props }) => {
	if (isCall) {
		return <ControlToRecipient {...props} />;
	}
	return <Recipient {...props} />
};

class Toolbar extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showSelectChannel: false
		};
		this.handleShowSelectChannel = this.handleShowSelectChannel.bind(this);
	}
	handleShowSelectChannel(v) {
		this.setState({showSelectChannel: v});
	}
	render() {
		const {
				channels
				, isCall
				, onSelectChannel
				, selectedChannel
			} = this.props
			;
		return (
			<div className="errand-edit">
				<div className="errand-edit-wrap">
					<ReplyToolbar
						show={true}
						showChannelOpt={!isCall}
						className="manual-errand-toolbar"
						enableAction={false}
						toolbarID={mErdToolbarID}
						options={channels}
						replyAs={RPLY_MANUAL}
						selectedChannel={selectedChannel}
						showChannelSelection={this.state.showSelectChannel}
						onSelectChannel={onSelectChannel}
						onToggleChannel={this.handleShowSelectChannel}
					/>
				</div>
			</div>
		);
	}
}

class ToggleableToolbar extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showSelectChannel: false,
			showToolbar: true
		};
		this.handleShowSelectChannel = this.handleShowSelectChannel.bind(this);
	}
	handleShowSelectChannel(v) {
		this.setState({showSelectChannel: v});
	}
	handleToggleToolbar = () => {
		this.setState({showToolbar: !this.state.showToolbar});
	}
	render() {
		const {
			channels
			, showChannelOpt
			, onSelectChannel
			, selectedChannel
		} = this.props
			;
		return (
				<div className="single-row">
					<ReplyToolbar
						show={this.state.showToolbar}
						showChannelOpt={showChannelOpt}
						className="manual-errand-toolbar"
						enableAction={false}
						toolbarID={mErdToolbarID}
						options={channels}
						replyAs={RPLY_MANUAL}
						selectedChannel={selectedChannel}
						showChannelSelection={this.state.showSelectChannel}
						onSelectChannel={onSelectChannel}
						onToggleChannel={this.handleShowSelectChannel}
					/>
					<ToolbarButtonIcon
						active={this.state.showToolbar}
						hidden={false}
						onClick={this.handleToggleToolbar}
					/>
				</div>
		);
	}
}

const SubjectInput = ({ id, wrapClassName, value, ...props }) => (
	<div className={wrapClassName}>
		<label htmlFor={id}>{I('SUBJECT: ')}</label>
		<input
			id={id}
			data-qa-id="manual-subject"
			type="text"
			value={value}
			{...props}
		/>
	</div>
);

// NOTE: subject selection component for future reference.
// class SubjectSelect extends React.PureComponent {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			show: false
// 		};
// 		this.toggle = this.toggle.bind(this);
// 		this.handleSelect = this.handleSelect.bind(this);
// 		this.labelClick = this.labelClick.bind(this);
// 	}
// 	handleSelect() {
// 		this.setState({show: false});
// 		if(v === UNSELECT) {
// 			return;
// 		}
// 		const data = this.props.options[v];
// 		this.props.onSelect(v, data.name);
// 	}
// 	toggle(v) {
// 		this.setState({show: v});
// 	}
// 	render() {
// 		const {onSelect, ...props} = this.props;
// 		return <LabeledSelectBox id="subject" onSelect={this.handleSelect}
// 			onToggleShow={this.toggle} show={this.state.show} {...props}
// 			title={I('SUBJECT: ')} top='32px' maxHeight='506px'
// 			selectClassName="errand-subject sidebar-nav-select"
// 			wrapClassName="label-block subj" />;
// 	}
// }
//
// SubjectSelect.propTypes = {
// 	selected: PropTypes.number
// 	, options: PropTypes.arrayOf(
// 		PropTypes.shape({
// 			name: PropTypes.string
// 			, value: PropTypes.string
// 		})
// 	)
// };

const withIdFieldSingleOptionHandler = createSingleOptionHandler("id");

const withConstantProps = textNoItemSelected => withProps({
	id: "account"
	, selectClassName: "errand-subject sidebar-nav-select"
	, wrapClassName: "label-block account"
	, textNoItemSelected
});

function createArrayDataWithUnselectDetector(optionsPropName) {
	return (props, prevProps) => {
		const data = props[optionsPropName];
		if (data.length
			&& data.length === 2
			&& data !== prevProps[optionsPropName]) {
			let item;
			$.each(data, (i, v) => {
				if (v.id !== UNSELECT) {
					item = v;
					return false;
				}
			});
			return item;
		}
	};
}

const AccountSelectBox = composeWithDisplayName(
	"AccountSelectBox"
	, withIdFieldSingleOptionHandler
	, createWithSingleOptionHandler(createArrayDataWithUnselectDetector("options"))
	, withConstantProps(I("Select an account"))
)(LabeledSelectBox);

class AccountSelectBase extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(v) {
		let data = {};
		for (let i = 0; i < this.props.options.length; i++) {
			if (this.props.options[i].id === v) {
				data = this.props.options[i];
			}
		}
		this.props.onSelect(
			this.props.type
			, data.id
			, data.accountId
			, data.value
		);
	}
	render() {
		const {
				isCall
				, onSelect
				, onSelectPhone
				, type
				, ...props
			} = this.props
			;
		return <AccountSelectBox onSelect={this.handleSelect} {...props} />;
	}
}

function createNormalizedDataDetector(normalizedPropsName, orderPropsName) {
	return (props, prevProps) => {
		const norm = props[normalizedPropsName]
			, order = props[orderPropsName]
			;
		if ((order.length && order.length === 1)
			&& (norm !== prevProps[normalizedPropsName]
				|| order !== prevProps[orderPropsName])) {
			return norm[order[0]];
		}
	};
}

const PhoneSelectBox = composeWithDisplayName(
	"withPhoneSelectBox"
	, renameProp("onSelectPhone", "onSelect")
	, mapProps(({ options, ...props }) => ({
		options: options.norm
		, order: options.order
		, tooltipProp: "value"
		, ...props
	}))
	, withIdFieldSingleOptionHandler
	, createWithSingleOptionHandler(createNormalizedDataDetector(
		"options"
		, "order"
	))
	, withConstantProps(I("Select a phone"))
)(LabeledSelection);

const AccountSelect = branch(
	({ isCall }) => isCall
	, renderComponent(PhoneSelectBox)
)(AccountSelectBase);

const TabWrapper = ({ children }) => (
	<div className="tab create active">
		<form action="#" className="form-create">
			{children}
		</form>
	</div>
);

class TabContent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			// TODO: this seem useless as it seem not controllable by us but
			// browser.
			showBrowseAttachmentPopup: false,
			showArchiveAttachmentPopup: false
		};
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
		this.handleLibraryClick = this.handleLibraryClick.bind(this);
		this.toggleShowArchive = this.toggleShowArchive.bind(this);
		this.handleContentClear = this.handleContentClear.bind(this);
		this.handleContentChange = this.handleContentChange.bind(this);
		this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);
		this.handleDragnDropFile = this.handleDragnDropFile.bind(this);
	}
	handleSelectAll(v) {
	}
	handleKeyDown() {
	}
	handleCkeditorChange(e) {
		const p = this.props
			, cke = e.editor
			, ckText = cke.document.getBody().getText()
			;
		this.handleContentChange(cke.getData(),ckText);
		if (p.selectedChannel === RC_TWITTER && p.currentReplyOption !== ME_NOTE) {
			let tweetContent = "";
			if (typeof ckText !== 'undefined') {
				tweetContent += this.getPreference(p.salutations, p.selectedSal);
				tweetContent += ckText;
				tweetContent += this.getPreference(p.signatures, p.selectedSig);
				let totalLength = tweetContent.length;
				if (totalLength > TWITTERTWEETLIMIT) {
					let numofTweet = Math.ceil(totalLength / TWITTERTWEETLIMIT);
					p.showTweetWarning(true, numofTweet);
				} else {
					p.showTweetWarning(false, 0);
				}
			}
		} else {
			if (p.shouldShowTweetWarning) {
				p.showTweetWarning(false, 0);
			}
		}
	}
	getPreference(prefs, selectedPref) {
		let contents = "";
		prefs.forEach((s, i) => {
			if (s.id === selectedPref) {
				contents = s.value;
				return;
			}
		});
		return contents;
	}
	handleLibraryClick() {
	}
	toggleShowArchive(currentShow) {
		this.setState({showArchiveAttachmentPopup: !currentShow});
	}
	handleContentClear(e) {
		let which = this.props.currentReplyOption === ME_NOTE ? "internal_comment" : "update_answer";
		this.props.onClear(which, false)
		if(this.props.selectedChannel === RC_WHATSAPP){
			const editor = CKEDITOR.instances["ckev5me"];
			if( editor ){
				console.log("dbg: template body cleaned, current channel: " + this.props.selectedChannel);
				editor.setReadOnly(false);
			}
		}
	}
	handleContentChange(html, plain) {
		let which = this.props.currentReplyOption === ME_NOTE ? "internal_comment" : "update_answer";
		this.props.onContentChange(which, html, plain);
	}
	handleAttachmentDelete(type, attachmentID, index, e, attachmentFileName) {
		let reply = this.props.currentReplyOption === ME_NOTE ? RPLY_MANUAL_COMMENT : RPLY_MANUAL;
		this.props.onAttachmentDelete(reply, type, attachmentID, index, e, attachmentFileName)
	}
	handleDragnDropFile(data, info) {
		let reply = this.props.currentReplyOption === ME_NOTE ? RPLY_MANUAL_COMMENT : RPLY_MANUAL;
		this.props.onDragnDropFile(reply, data, info)
	}
	handleToggleKnowledgeBase = () => {
		const p = this.props;
		if(p.selectedArea > 0){
			p.onToggleKnowledgeBase(!p.openKnowledgeBase);
		} else {
			alert(I("Please select an area to proceed."));
		}
	}
	onSelectSignature = (id) => {
		if(this.props.defaultSig){
			if(this.props.selectedSig > 0 && id === 0){
				this.props.setSignature(this.props.selectedSig);
			}else{
				this.props.setSignature(id);
			}
			this.props.setSignatureDefault();
		}else{
			this.props.setSignature(id);
		}
	}
	onSelectSalutation = (id) => {
		if(this.props.defaultSal){
			if(this.props.selectedSal > 0 && id === 0){
			   this.props.setSalutation(this.props.selectedSal);
		   }else{
			   this.props.setSalutation(id);
		   }
		   this.props.setSalutationDefault();
	   }else{
		   this.props.setSalutation(id);
	   }
	}
	render() {
		const {
			children
			, ckeditorSettings
			, createdId
			, disabled
			, insertionText
			, isCall
			, mobile
			, onCallClick
			, onHangupClick
			, onCallHoldClick
			, onChange
			, onChangeToInput
			, onOpenInsertProtectedContentReply
			, onResetInsertionText
			, replyOptions
			, selectedChannel
			, selectedTab
			, currentReplyOption
			, toInputValue
			, innerTabs
			, isBulkSend
			, ...p
		} = this.props
			, replyChData = replyOptions[selectedChannel]
			;
		let languageSrc
			, toolbar
			, title
			;
		if (replyChData) {
			toolbar = replyChData.toolbar;
		}
		if (!toolbar) {
			toolbar = DEFAULT_SIMPLE_TOOLBAR;
		}
		if (!createdId) {
			title = NEW_TITLE;
		} else {
			title = createdId;
		}
		let hideAttachment = false;
		if (selectedChannel == RC_SMS || selectedChannel == RC_VOICE) {
			hideAttachment = true;
		}
		if (ckeditorSettings.languageSrc
			&& ckeditorSettings.languageSrc.length) {
			languageSrc = ckeditorSettings.languageSrc;
		} else {
			languageSrc = emptyArray;
		}
		let callElem = <DialPadCtnr
							allowedButtonsChangeValue={allowEscToClear}
							hidden={!isCall}
							value={toInputValue}
							onCallClick={onCallClick}
							onChange={onChangeToInput}
							sipCallStatus={p.sipCallStatus}
							type={MP_CALL}
						/>;
		if(this.props.callType == MP_BASIC_CALL){
			callElem = <SimpleDialPadCtnr
							allowedButtonsChangeValue={allowEscToClear}
							hidden={!isCall}
							value={toInputValue}
							onCallClick={onCallClick}
							onHangupClick={onHangupClick}
							onCallHoldClick={onCallHoldClick}
							onChange={onChangeToInput}
							sipCallStatus={p.sipCallStatus}
							type={MP_BASIC_CALL}
						/>;
		}
		return (
			<TabWrapper>
				{currentReplyOption != ME_NOTE && <Titles
					answer={p.answer}
					plain={p.plain}
					appendTemplate={p.appendTemplate}
					areas={p.areas}
					centionLibrary={p.centionLibrary}
					disabled={disabled}
					isCall={isCall}
					number={title}
					selectedArea={p.selectedArea}
					selectedChannel={selectedChannel}
					selectedTab={selectedTab}
					selectedTags={p.selectedTags}
					salutations={p.salutations}
					selectedSal={p.selectedSal}
					selectedSig={p.selectedSig}
					setSalutation={p.setSalutation}
					setSalutationDefault={p.setSalutationDefault}
					defaultSal={p.defaultSal}
					setSignature={p.setSignature}
					signatures={p.signatures}
					setSignatureDefault={p.setSignatureDefault}
					defaultSig={p.defaultSig}
					tags={p.tags}
					templates={p.templates}
					showPreview={p.showPreview}
					previewDisabled={p.previewDisabled}
					onAreaChange={p.onAreaChange}
					onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
					onTagChange={p.onTagChange}
					onToggleKnowledgeBase={p.onToggleKnowledgeBase}
					openKnowledgeBase={p.openKnowledgeBase}
					onClickPreview={p.onClickPreview}
					currentSelected={p.currentSelected}
					selectedCreation={p.selectedCreation}
					showChannelOpt={!isCall}
					options={p.channels}
					replyAs={RPLY_MANUAL}
					onSelectChannel={p.onSelectChannel}
					onShowTemplateCustom={p.onShowTemplateCustom}
					showWATemplatePopup={p.showWATemplatePopup}
					isBulkSend={isBulkSend}
				/>
				}
				<SplitOrNot isCall={isCall} mobile={mobile}>
					<div>
						{children}
						{!isBulkSend && <SubjectInput
							id="meSubject"
							className="label-block subj"
							onChange={p.onSubjectChange}
							value={p.subject}
							disabled={disabled}
							wrapClassName="input subject"
						/>
						}
						<div className="v5-layout">
							{innerTabs}
							{currentReplyOption != ME_NOTE &&
								<div className="errand-titles">
									{features["cention-library"] &&
										<div className="field errand-knowledgebase">
											<i className="icon-knowledgebase"></i>
											<CtrlKnowledgeBasePopup
												id="errandKnowledgeBase"
												open={this.state.openKnowledgeBase}
												onToggle={this.handleToggleKnowledgeBase}
												title={I("Open Knowledge base")}
											/>
										</div>
									}
									{!isCall &&
										<Assist
											onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
											salutations={p.salutations}
											setSalutation={this.onSelectSalutation}
											selectedSal={p.selectedSal}
											signatures={p.signatures}
											setSignature={this.onSelectSignature}
											selectedSig={p.selectedSig}
											templates={p.templates}
											appendTemplate={p.appendTemplate}
											answer={p.answer}
											plain={p.plain}
											selectedChannel={selectedChannel}
											selectedTab={selectedTab}
											showPreview={p.showPreview}
											previewDisabled={p.previewDisabled}
											onClickPreview={p.onClickPreview}
											currentSelected={p.currentSelected}
											selectedCreation={p.selectedCreation}
											isCall={isCall}
											onShowTemplateCustom={p.onShowTemplateCustom}
											showWATemplatePopup={p.showWATemplatePopup}
											handleWATemplToggle={p.handleWATemplToggle}
											isBulkSend={isBulkSend}
										/>
									}
								</div>
							}
							<div className="toolbar-container">
								<ToggleableToolbar
									channels={p.channels}
									disabled={disabled}
									onSelectChannel={p.onSelectChannel}
									selectedChannel={selectedChannel}
									showChannelOpt={false}
								/>
							</div>
						</div>
						<Ckeditor
							id="ckev5me"
							insertionText={insertionText}
							isReadOnly={disabled}
							defaultFontFamily={ckeditorSettings.fontFamily}
							height={190}
							toolbarID={mErdToolbarID}
							defaultFontSize={ckeditorSettings.fontSize}
							defaultContent={p.content}
							spellLanguages={languageSrc}
							{...toolbar}
							fileArchiveImages={p.archives}
							onKeydown={this.handleKeyDown}
							onChange={this.handleCkeditorChange}
							onDragnDropFiles={this.handleDragnDropFile}
							onLibraryClick={this.handleLibraryClick}
							onResetInsertionText={onResetInsertionText}
							showResize={true}
							resizeMinHeight="100"
						/>
					</div>
					<div style={styleMaxHeight}>
						{callElem}
					</div>
				</SplitOrNot>
				{/* <Infos
					lastSavedTimestamp={p.lastSavedTimestamp}
					isModified={p.isModified}
					disabled={disabled}
				/> */}
				<FooterCheckboxes
					attachmentSize={p.attachmentSize}
					checkboxes={p.footerCheckboxes}
					disabled={disabled}
					checkedStates={p.footerCheckboxStates}
					currentRoot={selectedTab}
					onClick={p.onFooterCheckboxClick}
					hideAttachmentLink={hideAttachment}
					showUploadAttachmentBox={p.showUploadAttachmentBox}
					toggleUploadAttachment={p.toggleUploadAttachment}
					canTranslate={p.canTranslate}
					toLang={p.toLang}
					onSelectedToLang={p.onSelectedToLang}
					showAttachment={p.showManualPopup}
					selectedChannel={selectedChannel}
					content={p.content}
					shortUCS2={p.shortUCS2}
				/>
				<EditorFootnotes
					archiveImgs={p.archives}
					attachFile={p.attachFileOptions}
					attachments={p.attachments}
					currentReply={currentReplyOption === ME_NOTE ? RPLY_MANUAL_COMMENT : RPLY_MANUAL}
					isUploading={p.uploading}
					disabled={disabled}
					showAttachment={this.state.showBrowseAttachmentPopup}
					showArchiveDD={this.state.showArchiveAttachmentPopup}
					showUploadAttachmentBox={p.showUploadAttachmentBox}
					onAttachmentDelete={this.handleAttachmentDelete}
					onFileupload={p.onFileupload}
					onShowArchiveAttach={this.toggleShowArchive}
					onUploadArchive={p.onUploadArchive}
					hideAttachmentBar={hideAttachment}
				/>
				<Bttns
					buttonsState={p.buttonsState}
					isCall={isCall}
					state={p.state}
					callState={p.callState}
					disabled={disabled}
					selectedTab={selectedTab}
					selectedCreation={p.selectedCreation}
					onSelectCreation={p.onSelectCreation}
					onClear={this.handleContentClear}
					onSubmit={p.onSubmit}
					hideNewErrandMenu={p.hideNewErrandMenu}
					isBulkSend={isBulkSend}
				/>
				<TweetWarningMsg
					show={p.shouldShowTweetWarning}
					numberOfTweet={p.numberOfTweet}
				/>
			</TabWrapper>
		);
	}
}

const StartOrCreate = ({
	fromOptions
	, onFailValidation
	, onSelectAccount
	, onSelectPhone
	, onRecipientsChange
	, onToggleContactBook
	, recipientOptions
	, recipients
	, selectedAccount
	, toInputRef
	, ...props
}) => {
	const {
			disabled
			, isCall
			, onChangeToInput
			, replyOptions
			, selectedArea
			, selectedChannel
			, selectedTab
			, currentReplyOption
			, toInputValue
			, isBulkSend
		} = props
		, replyChData = replyOptions[selectedChannel]
		;
	let fullEmail
		, emailType
		, toFieldName
		, toMaxInput
		, showAccount
		, accountTitle
		, accountType
		, textNoAccountItem
		, showTofield = false
		;
	if (selectedChannel === RC_EMAIL) {
		emailType = true;
		showTofield = true;
	} else if (selectedChannel === RC_SMS) {
		showTofield = true;
	} else if (selectedChannel === RC_VOICE) {
		showTofield = true;
	} else {
		showAccount = true;
		if (!replyChData || !replyChData.manual) {
			accountType = UNKNOWN_WORKFLOW_SERVICE_TYPE;
		} else {
			if (isCall) {
				accountTitle = replyChData.manual.account;
				// showAccount = false; // TODO: temporary, will be removed.
			}
			accountType = replyChData.manual.type;
			textNoAccountItem = replyChData.manual.choose;
		}
		if (!accountTitle) {
			accountTitle = DEFAULT_ACCOUNT_TITLE;
		}
		accountTitle += ': ';
		if (isCall || selectedChannel === RC_LINE || selectedChannel === RC_WHATSAPP) {
			showTofield = true;
		}
	}
	if (currentReplyOption === ME_NOTE) {
		showAccount = false;
		showTofield = true;
		toFieldName = FROM_TITLE;
		fullEmail = false;
	} else if (selectedTab === ME_START || isCall) {
		// send manual errand target use 'to' label referring recipient
		// customer address. The manual errand from field will automatically
		// same as this value.
		toFieldName = TO_TITLE;
		toMaxInput = -1; // -1 is unlimited
		if (emailType) {
			fullEmail = true;
		}
	} else {
		// create manual errand without send use the 'from' label referring
		// to the manual errand come 'from' customer.
		toFieldName = FROM_TITLE;
		toMaxInput = MANUAL_ERRAND_MAX_RECIPIENT
	}
	if(props.callType === MP_BASIC_CALL){
		showAccount = false;
	}
	if(isBulkSend) {
		showAccount = false;
		showTofield = false;
		fullEmail = false;
	}
	return (
		<TabContent {...props}>
			{showAccount && <AccountSelect
				isCall={isCall}
				type={accountType}
				title={accountTitle}
				options={fromOptions}
				disabled={disabled}
				onSelect={onSelectAccount}
				onSelectPhone={onSelectPhone}
				selected={selectedAccount}
				selectedArea={selectedArea}
			/>}
			{showTofield && <ToRecipient
				className={classNames({"full-width": isCall})}
				disabled={disabled}
				fieldName={toFieldName}
				id="errand-email-to"
				inputRef={toInputRef}
				inputValue={toInputValue}
				isCall={isCall}
				callType={props.callType}
				maxInput={toMaxInput}
				name={RECIPIENT_TO}
				selected={recipients.to}
				// selectedTab={selectedTab}
				type={selectedChannel}
				onChange={onRecipientsChange}
				onChangeInput={onChangeToInput}
				onFailValidation={onFailValidation}
				onToggleContactBook={onToggleContactBook}
				options={recipientOptions}
			/>}
			{fullEmail && fullEmailRecipientList.map(v => {
				const { fieldName, id, selected } = recipientsMap[v];
				return (
					<Recipient
						key={v}
						className="sm"
						disabled={disabled}
						fieldName={fieldName}
						id={id}
						name={v}
						selected={recipients[selected]}
						type={RC_EMAIL}
						onChange={onRecipientsChange}
						onFailValidation={onFailValidation}
						onToggleContactBook={onToggleContactBook}
						options={recipientOptions}
					/>
				);
			})}
		</TabContent>
	);
};

const OneTabBase = ({ active, disabled, onClick, text, icon, className, ...props }) => (
	<span
		className={classNames("toggle-tabs",{active}, { [`${className}`]: true })}
		data-qa-id={props["data-qa-id"]}
		data-rel={props["data-rel"]}
		disabled={disabled}
		onClick={onClick}
	>
	{icon && <i className={icon}></i>}
	{L(text)}
	</span>
);

const OneTabBaseWithTooltip = ({ active, disabled, onClick, text, icon, className, tooltiptext, ...props }) => (
	<span
		className={classNames("toggle-tabs",{active}, { [`${className}`]: true }) + " errandTooltip"}
		data-qa-id={props["data-qa-id"]}
		data-rel={props["data-rel"]}
		disabled={disabled}
		onClick={onClick}
	>
	{icon && <i className={icon}></i>}
	<span className="tooltiptext">{tooltiptext}</span>
	{L(text)}
	</span>
);

export const OneTab = compose(
	withIdAttachedOnClick
	, withDisableableOnClick
)(OneTabBase);

export const OneTabWithTooltip = compose(
	withIdAttachedOnClick
	, withDisableableOnClick
)(OneTabBaseWithTooltip);

const Tabs = ({ disabled, isCall, isBulkSend, onSelect, selectedTab}) => {
	let tabs
		, activator
		;
	if (isCall) {
		tabs = [TT_CALL];
		activator = () => true;
		onSelect = doNothing;
	} else {
		if(isBulkSend) {
			tabs = bulkSendTab;
			activator = v => selectedTab === v;
		} else {
			tabs = errandTabs;
			activator = v => selectedTab === v;
		}
	}
	return (
		<div className="popup-tabs">
			{tabs.map(v => {
				const { id, rel, text, type, icon } = tabsMap[v];
				return (
					<OneTab
						key={v}
						active={activator(v)}
						id={type}
						onClick={onSelect}
						data-qa-id={id}
						data-rel={rel}
						text={text}
						icon={icon}
						className={isCall ? "outbound-call" : ""}
						disabled={disabled}
					/>
				);
			})}
		</div>
	);
};

const CallReplyTabs = ({ tabs, currentTab, disabled, onSelectReply }) => {
	let activator = v => currentTab === v;
	return <div className={classNames("popup-reply-tabs", {disabled: disabled})}>
			{tabs.map(v => {
				let label = manualReplyOptions[v.key];
				return (
					<OneTab
						key={v.id}
						id={v.id}
						active={activator(v.id)}
						onClick={onSelectReply}
						data-qa-id={v.id}
						data-rel={v.key}
						text={label.name}
					/>
				);
			})}
		</div>
};

const ManualErrandNotReady = props => (
	<div className={classNames(baseClassName, "open")}>
		{I("Fetching workflow settings ...")}
	</div>
);

const Wrapper = ({ children, show, onClick, ...props }) => (
	<div className={classNames(baseClassName, {open: show, v5: true})} >
		<div className="errand-inner-back" data-qa-id="errand-inner-back-select" style={{zIndex: 500, position: "absolute", height: "100%", width: "100%"}} onClick={onClick}></div>
		<div className="errand-inner popup-inner" style={{zIndex:501}}>{children}</div>
	</div>
);

const manualReplyOptions = {
	[RPLY_ERRAND]: {
		id: RPLY_ERRAND,
		name: I('REPLY'),
		hotkey: getHotkeyChar(ERRAND_HOTKEYS,TOGGLE_REPLY)
	},
	[RPLY_COMMENT]: {
		id: 'internal-comment',
		name: I('INTERNAL COMMENT'),
		hotkey: getHotkeyChar(ERRAND_HOTKEYS,TOGGLE_COMMENT)
	},
	[RPLY_QUESTION]: {
		id: RPLY_QUESTION,
		name: I('QUESTION'),
		hotkey: getHotkeyChar(ERRAND_HOTKEYS,TOGGLE_QUESTION)
	}
}

export const ManualReplyNav = ({
	disable
	, currentReply
	, onSelectReply
	, options
}) => {
	let nav = [];
	$.each(options, (i, v) => {
		const k = v.key;
		const id = v.id;
		v = manualReplyOptions[k];
		nav.push(
			<OneReplyNav
				key={k}
				me={id}
				myClass={classNames("jq-radio", {checked: id === currentReply})}
				name={v.name}
				hotkey={v.hotkey}
				id={"QA_tab_"+v.id}
				onSelectReply={onSelectReply}
				disabled={disable}
				busy={false}
			/>
		);
	});
	return <div className="block-reply-nav">
		<ul className="row errand-box-reply-nav mx-0">{nav}<li></li></ul>
	</div>
};

class ManualErrandBase extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleCallClick = this.handleCallClick.bind(this);
		this.handleHangupClick = this.handleHangupClick.bind(this);
		this.handleCallHoldClick = this.handleCallHoldClick.bind(this);
		this.handleCallResumeClick = this.handleCallResumeClick.bind(this);
		this.handleChangeToInput = this.handleChangeToInput.bind(this);
		this.handleToInputRef = this.handleToInputRef.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleOnClose = this.handleOnClose.bind(this);
		this.handleOnMinimize = this.handleOnMinimize.bind(this);
		this.isCallOngoing = this.isCallOngoing.bind(this);
		this.hidePopup = this.hidePopup.bind(this);
		this.state = {toInputValue: "", show: 2};
	}
	handleCallClick() {
		if (this.props.phone) {
			if(this.props.showCall === MP_BASIC_CALL){
				if(this.props.subject == ""){
					this.props.onApiCallClick(this.props.phone, true);
				} else {
					this.props.onApiCallClick(this.props.phone, false);
				}
			} else {
				this.props.onCallClick(this.props.phone);
			}
			return;
		}
		if (!this.toInputRef) {
			console.log("dbg: invalid to-input ref");
			return;
		}
		this.props.onDelayCallClick();
		this.toInputRef.accept();
	}
	handleHangupClick() {
		if(this.props.showCall === MP_BASIC_CALL){
			this.props.onApiHangupClick(this.props.phone);
		}
	}
	handleCallHoldClick(isHold, isManual) {
		if(this.props.showCall === MP_BASIC_CALL){
			this.props.onCommonCallAPI(CALL_STR_HOLD, this.props.phone,
				isHold, isManual);
		}
	}
	handleCallResumeClick() {
	}
	handleChangeToInput(toInputValue) {
		if(this.props.showCall === MP_BASIC_CALL){
			let which = [];
			which.push({id: toInputValue, value: toInputValue});
			this.props.onRecipientsChange(which,"to");
		}
		this.setState({toInputValue});
	}
	handleToInputRef(ref) {
		this.toInputRef = ref;
	}
	handleDeleteClick(){
		let which = [];
		let oldValue = this.state.toInputValue;
		if(this.props.showCall === MP_BASIC_CALL) {
			oldValue = (this.props.recipients.to[0]);
		}
		if(typeof oldValue !== 'undefined' && oldValue != null &&
			oldValue.length >0){
			let newValue = oldValue.slice(0, -1);
			which.push({id: newValue, value: newValue});
			this.props.onRecipientsChange(which,"to");
			this.setState({toInputValue: newValue});
		}
	}
	isCallOngoing(){
		const p = this.props;
		let sipEnabled = (p.wfSettings.aventaEnabled ||
			p.wfSettings.sipEnabled);
		if(sipEnabled && p.showCall == MP_BASIC_CALL){
			if(p.sipCallStatus == SIP_REGISTER || p.sipCallStatus ==
				SIP_CALL_IDLE || p.sipCallStatus == SIP_CONNECTED || p.sipCallStatus == SIP_DISCONNECTED){
				return false;
			} else {
				if(p.sipIncomingOngoing == true && (p.sipCallStatus ==
					SIP_CALL_CONNECTING || p.sipCallStatus ==
					SIP_CALL_CONNECTED)){
					return false;
				}
				return true;
			}
		}
		return false;
	}
	handleOnClose() {
		const isCall = (this.props.showCall === MP_BASIC_CALL || this.props.showCall === MP_CALL);
		let state = isCall ? this.props.callState : this.props.state;
		let isCurrentErrand = false;
		if(this.props.sipMakeCallCurrentErrand) {
			isCurrentErrand = true;
		}
		if(this.isCallOngoing() == true) {
			return;
		}
		let forSipCall = this.props.showCall === MP_BASIC_CALL
		, erdCreated = state === ME_ST_CREATED;
		if(!forSipCall) {
			this.props.onTagChange(TAG_CLEAR, null, true);
		}
		this.props.onClose(isCall, forSipCall, erdCreated, isCurrentErrand);
		this.setState({toInputValue: ""});
	}
	handleOnMinimize() {
		let toValue="";
		if(typeof this.props.updateTo[0] !== 'undefined'){
			toValue = this.props.updateTo[0].value;
		}
		this.props.onMinimizeCall(toValue);
	}
	handleToggleTempl = () =>{
		this.props.handleWATemplToggle(this.props.showWATemplatePopup);
	}
	handleInsert = (code, templCont) =>{
		//FIXME(Mujibur):need to handle the enable/disable from component level, somehow currently is not working...
		const editor = CKEDITOR.instances["ckev5me"];
		if( editor ){
			console.log("dbg: whatsapp template " + this.props.selectedChannel);
			editor.setData(templCont);
			editor.setReadOnly(true);
			let strCode = JSON.stringify(code);
			this.props.saveWATemplateCode( strCode );
			this.props.appendTemplate(templCont);
		}
	}
	hidePopup(){
		if(this.isCallOngoing() == true) {
			return;
		}
		let forSipCall = this.props.showCall === MP_BASIC_CALL
		, erdCreated = this.props.state === ME_ST_CREATED;
		if(!forSipCall) {
			this.props.onTagChange(TAG_CLEAR, null, true);
		}
		this.props.onClose(forSipCall, erdCreated);
		this.setState({toInputValue: ""});
	}
	render() {
		const {
				insertionText
				, mobile
				, onResetInsertionText
				, onClose
				, show
				, showCall
				, wfSettings
				, sipMakeCallCurrentErrand
				, openedErrandDisplayId
				, showBulkSend
				, ...props
			} = this.props
			, showPopup = (show !== MP_NONE || showBulkSend)
			, showCallPopup = (showCall !== MP_NONE && showCall !== MP_MINIMIZE)
			;
		if (!wfSettings && !showPopup && !showCallPopup) {
			return null;
		} else if (!wfSettings) {
			return <ManualErrandNotReady />;
		}
		const {
				onOpenInsertProtectedContentReply
				, onSelectPhone
				, onSelectTab
				, selectedAccount
				, selectedTab
				, currentReplyOption
				, state
				, callState
				, ...p
			} = props
			, isCall = (showCallPopup && (showCall === MP_CALL || showCall === MP_BASIC_CALL)) || sipMakeCallCurrentErrand
			;
		let opts = {}
			, closeDisable
			, uiDisable
			, callCreate
			, manualState = state
			;
		if(isCall) {
			manualState = callState;
		}
		if (manualState === ME_ST_BUSY) {
			closeDisable = true;
			uiDisable = true;
		} else if (manualState === ME_ST_CREATED) {
			uiDisable = true;
		} else if (manualState === ME_ST_IDLE){
			uiDisable = false;
		}
		$.each(p.channels, (i, v) => {
			opts[v] = OPT_CHANNELS[v];
		});

		const replyOptions = showBulkSend ? bulkSendOptions :
			selectedTab === ME_CREATE ? createReplyOptions :
			selectedTab === ME_START ? startReplyOptions : [];
		let replyNav= <ManualReplyNav
						 disable={uiDisable}
						 currentReply={currentReplyOption}
						 options={replyOptions}
						 onSelectReply={(isCall ? p.onSelectCallReplyOption : p.onSelectReplyOption)}
					/>
		let greyStyle={}
		let sipEnabled = (this.props.wfSettings.aventaEnabled ||
			this.props.wfSettings.sipEnabled);
		if(sipEnabled && isCall){
			replyNav = <CallReplyTabs
						tabs={replyOptions}
						currentTab={currentReplyOption}
						onSelectReply={(isCall ? p.onSelectCallReplyOption : p.onSelectReplyOption)}
						disabled={sipMakeCallCurrentErrand}
				/>
			let toValue="";
			if(typeof this.props.updateTo[0] !== 'undefined'){
				toValue = this.props.updateTo[0].value;
			}
			callCreate = <ManualCallCreate
						innerTabs={replyNav}
						selectedTab={selectedTab}
						currentReplyOption={currentReplyOption}
						archives={p.archives}
						attachFileOptions={p.attachFileOptions}
						attachments={p.attachments}
						attachmentSize={p.attachmentSize}
						buttonsState={p.buttonsState}
						ckeditorSettings={wfSettings.answerWysiwyg}
						footerCheckboxes={p.footerCheckboxes}
						footerCheckboxStates={p.footerCheckboxStates}
						insertionText={insertionText}
						isCall={isCall}
						mobile={mobile}
						onCallClick={this.handleCallClick}
						onDeleteClick={this.handleDeleteClick}
						tags={p.tags}
						toInputRef={this.handleToInputRef}
						toInputValue={toValue}
						selectedTags={p.selectedTags}
						areas={p.areas}
						selectedArea={p.selectedArea}
						salutations={p.salutations}
						setSalutation={p.setSalutation}
						selectedSal={p.selectedSal}
						setSalutationDefault={p.setSalutationDefault}
						defaultSal={p.defaultSal}
						signatures={p.signatures}
						setSignature={p.setSignature}
						selectedSig={p.selectedSig}
						setSignatureDefault={p.setSignatureDefault}
						defaultSig={p.defaultSig}
						templates={p.templates}
						appendTemplate={p.appendTemplate}
						answer={p.answer}
						plain={p.plain}
						showPreview={p.showPreview}
						previewDisabled={p.previewDisabled}
						createdId={p.createdId}
						newErrandId={p.newErrandId}
						mCipherKey={p.mCipherKey}
						sipPhone={p.sipPhone}
						disabled={uiDisable}
						selectedAccount={selectedAccount}
						selectedCreation={p.createAs}
						content={currentReplyOption == ME_NOTE ? p.comment : p.content}
						subject={p.subject}
						replyOptions={OPT_CHANNELS}
						uploading={p.uploading}
						fromOptions={p.fromOptions}
						recipientOptions={p.recipientOptions}
						from={p.from}
						recipients={p.recipients}
						disableCreate={p.disableCreate}
						isModified={p.isModified}
						state={manualState}
						lastSavedTimestamp={p.lastSavedTimestamp}
						showUploadAttachmentBox={p.showUploadAttachmentBox}
						toggleUploadAttachment={p.toggleUploadAttachment}
						selectedChannel={p.selectedChannel}
						channels={opts}
						onChangeToInput={this.handleChangeToInput}
						handleDtmf={p.handleDtmf}
						onContentChange={p.onContentChange}
						onAreaChange={p.onAreaChange}
						onAttachmentDelete={p.onAttachmentDelete}
						onDragnDropFile={p.onDragnDropFile}
						onFailValidation={p.onFailValidation}
						onFileupload={p.onFileupload}
						onFooterCheckboxClick={p.toggleFooterCheckboxClick}
						onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
						onTagChange={p.onTagChange}
						onUploadArchive={p.onUploadArchive}
						onFromChange={p.onFromChange}
						onRecipientsChange={p.onRecipientsChange}
						onSelectAccount={p.onSelectAccount}
						onSelectCreation={p.onSelectCreation}
						onSelectChannel={p.onSelectChannel}
						onSelectPhone={onSelectPhone}
						onSubjectChange={p.onSubjectChange}
						onToggleContactBook={p.onToggleContactBook}
						onClear={p.onClear}
						onSubmit={p.onSubmit}
						canTranslate={p.canTranslate}
						toLang={p.toLang}
						onSelectedToLang={p.onSelectedToLang}
						centionLibrary={wfSettings.centionLibrary}
						openKnowledgeBase={p.openKnowledgeBase}
						shouldShowTweetWarning={p.shouldShowTweetWarning}
						numberOfTweet={p.numberOfTweet}
						showTweetWarning={p.showTweetWarning}
						onResetInsertionText={onResetInsertionText}
						onToggleKnowledgeBase={p.onToggleKnowledgeBase}
						onClickPreview={p.onClickPreview}
						currentSelected={p.currentSelected}
						callType={showCall}
						sipCallStatus={p.sipCallStatus}
						sipCallIsRecording={p.sipCallIsRecording}
						recordSipCall={p.recordSipCall}
						sipAllowRecord={wfSettings.sipAllowRecord}
						sipHideColdTransfer={wfSettings.sipHideColdTransfer}
						sipHideWarmTransfer={wfSettings.sipHideWarmTransfer}
						sipPhone={p.sipPhone}
						aventaEnabled={wfSettings.aventaEnabled}
						sipEnabled={wfSettings.sipEnabled}
						onHangupClick={this.handleHangupClick}
						onCallHoldClick={this.handleCallHoldClick}
						hideNewErrandMenu={wfSettings["hide-new-errands-menu"]}
						showManualPopup={showCallPopup}
						voiceExtensionId={wfSettings.voiceExtensionId}
						answerTransferCall={this.props.answerTransferCall}
						finalizeWarmTransferToAgent={this.props.finalizeWarmTransferToAgent}
						commonApiCall={this.props.onCommonCallAPI}
						sipCallTimer={this.props.sipCallTimer}
						resetSipTimer={this.props.resetSipTimer}
						sipCallTransferStatus={this.props.sipCallTransferStatus}
						sipXferMode={this.props.sipXferMode}
						sipCallTransferIsExternal={this.props.sipCallTransferIsExternal}
						sipMakeCallCurrentErrand={sipMakeCallCurrentErrand}
					/>
			greyStyle = {background: "#ececec"};
		}
		let sipRingerEnabled = false;
		if(this.props.sipServerUrl != ""){
			sipRingerEnabled = true
		}
		return (
			<Wrapper show={showPopup || showCallPopup}  mobile={mobile} onClick={this.handleOnClose}>
				<div className="wrap-errand">
					{p.showWATemplatePopup && <ModalPopup
						viewAs="template"
						id="waTemplateAnswer"
						show={p.showWATemplatePopup}
						dataSrc={p.templContent}
						templId={p.waTemplId}
						isHeader={true}
						headerText={I("Custom variable for WhatsApp template")}
						isFooter={true}
						onToggle={this.handleToggleTempl}
						onSend={this.handleInsert}
						onChange={this.handleOnInsertVar}
					/>}
					{sipRingerEnabled &&
					 <CallRinger play={this.props.sipCallStatus == SIP_CALL_CONNECTING || this.props.sipCallStatus == SIP_OUTGOING_CALL_CONNECTING} forSIP={true} presetRingingDevice={wfSettings["sip.ringing-output-device"]} />
					}
					<Tabs
						disabled={uiDisable}
						isCall={isCall}
						isBulkSend={showBulkSend}
						onSelect={onSelectTab}
						selectedTab={selectedTab}
					/>
					{callCreate}
					{!(sipEnabled && isCall) &&
					<StartOrCreate
						innerTabs={replyNav}
						selectedTab={selectedTab}
						currentReplyOption={currentReplyOption}
						archives={p.archives}
						attachFileOptions={p.attachFileOptions}
						attachments={p.attachments}
						attachmentSize={p.attachmentSize}
						buttonsState={p.buttonsState}
						ckeditorSettings={wfSettings.answerWysiwyg}
						footerCheckboxes={p.footerCheckboxes}
						footerCheckboxStates={p.footerCheckboxStates}
						insertionText={insertionText}
						isCall={isCall}
						mobile={mobile}
						onCallClick={this.handleCallClick}
						tags={p.tags}
						toInputRef={this.handleToInputRef}
						toInputValue={this.state.toInputValue}
						selectedTags={p.selectedTags}
						areas={p.areas}
						selectedArea={p.selectedArea}
						salutations={p.salutations}
						setSalutation={p.setSalutation}
						selectedSal={p.selectedSal}
						setSalutationDefault={p.setSalutationDefault}
						defaultSal={p.defaultSal}
						signatures={p.signatures}
						setSignature={p.setSignature}
						selectedSig={p.selectedSig}
						setSignatureDefault={p.setSignatureDefault}
						defaultSig={p.defaultSig}
						templates={p.templates}
						appendTemplate={p.appendTemplate}
						answer={p.answer}
						plain={p.plain}
						showPreview={p.showPreview}
						previewDisabled={p.previewDisabled}
						createdId={p.createdId}
						disabled={uiDisable}
						selectedAccount={selectedAccount}
						selectedCreation={p.createAs}
						content={currentReplyOption == ME_NOTE ? p.comment : p.content}
						subject={p.subject}
						replyOptions={OPT_CHANNELS}
						uploading={p.uploading}
						fromOptions={p.fromOptions}
						recipientOptions={p.recipientOptions}
						from={p.from}
						recipients={p.recipients}
						disableCreate={p.disableCreate}
						isModified={p.isModified}
						state={state}
						lastSavedTimestamp={p.lastSavedTimestamp}
						showUploadAttachmentBox={p.showUploadAttachmentBox}
						toggleUploadAttachment={p.toggleUploadAttachment}
						selectedChannel={p.selectedChannel}
						shortUCS2={wfSettings.sms_ucs2_use_67_bytes}
						channels={opts}
						onChangeToInput={this.handleChangeToInput}
						onContentChange={p.onContentChange}
						onAreaChange={p.onAreaChange}
						onAttachmentDelete={p.onAttachmentDelete}
						onDragnDropFile={p.onDragnDropFile}
						onFailValidation={p.onFailValidation}
						onFileupload={p.onFileupload}
						onFooterCheckboxClick={p.toggleFooterCheckboxClick}
						onOpenInsertProtectedContentReply={onOpenInsertProtectedContentReply}
						onTagChange={p.onTagChange}
						onUploadArchive={p.onUploadArchive}
						onFromChange={p.onFromChange}
						onRecipientsChange={p.onRecipientsChange}
						onSelectAccount={p.onSelectAccount}
						onSelectCreation={p.onSelectCreation}
						onSelectChannel={p.onSelectChannel}
						onSelectPhone={onSelectPhone}
						onSubjectChange={p.onSubjectChange}
						onToggleContactBook={p.onToggleContactBook}
						onClear={p.onClear}
						onSubmit={p.onSubmit}
						canTranslate={p.canTranslate}
						toLang={p.toLang}
						onSelectedToLang={p.onSelectedToLang}
						centionLibrary={wfSettings.centionLibrary}
						openKnowledgeBase={p.openKnowledgeBase}
						shouldShowTweetWarning={p.shouldShowTweetWarning}
						numberOfTweet={p.numberOfTweet}
						showTweetWarning={p.showTweetWarning}
						onResetInsertionText={onResetInsertionText}
						onToggleKnowledgeBase={p.onToggleKnowledgeBase}
						onClickPreview={p.onClickPreview}
						currentSelected={p.currentSelected}
						callType={show}
						sipCallStatus={p.sipCallStatus}
						aventaEnabled={wfSettings.aventaEnabled}
						sipEnabled={wfSettings.sipEnabled}
						onHangupClick={this.handleHangupClick}
						onCallHoldClick={this.handleCallHoldClick}
						hideNewErrandMenu={wfSettings["hide-new-errands-menu"]}
						showManualPopup={showPopup}
						isBulkSend={showBulkSend}
						onShowTemplateCustom={p.onShowTemplateCustom}
						showWATemplatePopup={p.showWATemplatePopup}
						handleWATemplToggle={p.handleWATemplToggle}
					/>
					}
				</div>
				<div
					className="popup-minimize"
					onClick={this.handleOnMinimize}
					hidden={!((isCall && this.props.showCall === MP_BASIC_CALL && manualState === ME_ST_CREATED) || sipMakeCallCurrentErrand)}
					disabled={closeDisable}
				>
					<i className="icon-minimize" />
				</div>
				<div
					className="popup-close"
					onClick={this.handleOnClose}
					disabled={closeDisable}
				>
					<i className="icon-times" />
				</div>
			</Wrapper>
		);
	}
}

export const ManualErrand = withIdentity( // withRandomMultiProps withIdentity
	ManualErrandBase
	, 5000
	, 0
	, [
		{selectedChannel: RC_VOICE, showCall: MP_CALL}
		// , {selectedChannel: RC_EMAIL, selectedTab: ME_START}
		// , {selectedChannel: RC_EMAIL, selectedTab: ME_CREATE}
	]
);
