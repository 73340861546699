import React, { PureComponent } from 'react';
import { branch, compose } from 'recompose';
import update from 'immutability-helper';
import classNames from 'classnames';
import { ChannelIcon } from '../../reactcomponents/Channel';
import { ConversationCheckbox } from '../../reactcomponents/SquareCheckbox';
import { withConfirmationExternalLink } from '../../containers/hocs';
import { TagList as AttachmentTagList } from './Attachments';
import EnchancedMoment from './EnchancedMoment';
// TODO: remove this but at the moment it is needed to divert cyclic reference
// to avoid JS error.
import { withAgentTimezonOffset } from '../../views/v5/errandCtnrs';
import {
	CLOSED,
	PF_TIMESTAMP,
	UNKNOWN_DATE,
	ACTIONS_HISTORY_ICONS,
	MT_NOTE,
	MT_COLLABORATION,
	DEFAULT_ERRAND_LENGTH_LIMIT,
	MESSENGER_ICON_LOVE,
	MESSENGER_ICON_SMILE,
	MESSENGER_ICON_ANGRY,
	MESSENGER_ICON_SAD,
	MESSENGER_ICON_WOW,
	MESSENGER_ICON_LIKE,
	MESSENGER_ICON_DISLIKE,
	SENT
} from '../../common/v5/constants';
import { linkifyUrls
	, getRandomString
} from '../../common/v5/helpers';
import { TranslateNotes } from './ErrandTranslation'
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import { ChatPanelWrapper } from '../../reactcomponents/Sidepanel';
import TruncateText from './TruncateText';
import onClickOutside from 'react-onclickoutside';
import Measure from 'react-measure';
import {L} from '../../common/v5/config';


const messageActionIcons = {
		clone: 'icon-copy',
		edit: 'icon-pencil',
		delete: 'icon-trash',
		resend: 'icon-share',
		reply: 'icon-reply',
		print: 'icon-printer',
		translate: 'fa fa-globe',
		note: 'far fa-sticky-note',
		printThread: 'icon-printer',
		createNew: 'fa fa-file'
	};

const getDefaultMessageActionsOrder = (type) => {
	const defaultMessageActionsOrder = [
		{action: 'print', label: I("Print")},
		{action: 'clone', label: I("Copy")},
		{action: 'edit', label: I("Edit")},
		{action: 'delete', label: I("Delete")},
		{action: 'resend', label: (type == MT_COLLABORATION ? I("Send new") : I("Resend"))},
		{action: 'reply', label: I("Reply")},
		{action: 'translate', label: I("Translate")},
		{action: 'note', label: I("Errand note")},
		{action: 'printThread', label: I("Print Thread")},
		{action: 'createNew', label: I("Create new query for this thread")},
	];
	return defaultMessageActionsOrder;
}

const pseudoCFLAG_enableUnsafeExternalLinkDetect = true;

const TextTruncateAndExternalLink = branch(
	({ isChat }) => isChat && pseudoCFLAG_enableUnsafeExternalLinkDetect
	, withConfirmationExternalLink
)(TruncateText);

class MetaOneAction extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		const p = this.props;
		p.onActionClick(p.action, p.myErrandId, p.msg);
	}
	render() {
		const p = this.props, action = p.action,
			icon = messageActionIcons[action];
		if(!icon) {
			return null;
		}
		return <li>
			<div onClick={this.handleClick} data-qa-id={"QA_msgAction_" + p.action} className="errand-message-actions-button">
				<i className={icon} aria-hidden="true" title={p.title} ></i>
				{p.label &&
					<span>{L(p.label)}</span>
				}
			</div>
		</li>;
	}
}

class StaticMetaActions extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			showMoreActions: false
		}
		this.handleToggleActions = this.handleToggleActions.bind(this);
	}
	handleToggleActions(){
		this.setState({showMoreActions: !this.state.showMoreActions})
	}
	handleClickOutside() {
		if(this.state.showMoreActions)
			this.setState({showMoreActions: false})
	}
	render() {
		const p = this.props, isAgent = p.isAgent;
		const strMoreAction = I("More actions");
		let order, showSize, icons = [], moreIcons = [];
		let errandClass = this.props.type=='INTERNALCHAT' ? '' : 'errand-';
		if(p.icons) {
			order = p.icons;
		} else {
			order = [];
			const defaultMessageActionsOrder = getDefaultMessageActionsOrder(p.type);
			$.each(defaultMessageActionsOrder, function(i,v) {
				if(p[v.action]) {
					order.push(v);
				}
			});
		}
		if(typeof p.showSize !== 'undefined' && p.showSize >= 0) {
			showSize = p.showSize;
		} else {
			showSize = order.length;
		}
		$.each(order, function(i,v) {
			if(p[v.action]) {
				icons.push(<MetaOneAction key={'icon-'+v.action} action={v.action} myErrandId={p.myErrandId}
					onActionClick={p.onActionClick} msg={p.msg} title={v.label} />);

				moreIcons.push(<MetaOneAction key={'subicon-'+v.action} action={v.action} myErrandId={p.myErrandId}
					onActionClick={p.onActionClick} msg={p.msg} title={v.label} label={v.label} />);
			}
		});
		if(icons.length > showSize && !p.showAll) {
			const spread = <li key={'icon-spread'} onClick={this.handleToggleActions} data-qa-id={"QA_msgAction_showMoreAct"} title={strMoreAction}><p>
				<i className="icon-spread" data-qa-id={"QA_msgAction_showMoreAct_icon"}></i></p>
					<div className={errandClass+"message-actions-links"} hidden={!this.state.showMoreActions}>
						<ul>
							{moreIcons}
						</ul>
					</div>
				</li>;
			if(isAgent) {
				icons.splice(showSize, icons.length - showSize);
				icons.push(spread);
			} else {
				icons.splice(0, icons.length - showSize);
				icons.unshift(spread);
			}
		}
		return (
			<ul className={errandClass+"message-actions"}>
				{icons}
			</ul>
		);
	}
}

const MetaActions = onClickOutside(StaticMetaActions);

const ChannelText = ({ text, via }) => (
	<span>
		{via ? ' via ' : ', from '}
		<span className="errand-channel">{text}</span>
	</span>
)

class MetaFootnotes extends React.PureComponent {
	constructor(props){
		super(props);
		this.handleShowActions = this.handleShowActions.bind(this);
		this.state = {
			showActions: false
		}
	}
	handleShowActions(){
		let show = this.state.showActions;
		show = update(this.state.showActions, {$set: !this.state.showActions});
		this.setState({showActions: show});
		this.props.onShowActions(show);
	}
	render() {
		const {
			channelId,
			noTZParse,
			parseFormat,
			time,
			toText,
			toAgent,
			channelIcon,
			...p
		} = this.props
		const timeTxt = p.status + ' '
		const className = classNames({
			error: p.error, 'float-left': p.type === 'INTERNALCHAT'
		})
		let timeComp
			, showActionsTitle = I('show actions')
			, showActions
			;
		let errandNumber = null;
		if(p.showErrandNumber) {
			if (this.props.hid) {
				errandNumber = <span className='errand-number'>{I("#{errandNumber} ").replace('{errandNumber}', this.props.hid)}</span>;
			} else if (this.props.displayId){
				errandNumber = <span className='errand-number'>{I("#{errandNumber} ").replace('{errandNumber}', this.props.displayId)}</span>;
			} else {
				errandNumber = null;
			}
		}
		if (time && time !== UNKNOWN_DATE) {
			timeComp = (
				<EnchancedMoment
					date={time}
					parseFormat={parseFormat}
					noTZParse={noTZParse}
					showExactDayAndTime={p.showExactDayAndTime}
				/>
			);
		} else {
			timeComp = null;
		}
		if(this.state.showActions) {
			showActionsTitle = I("hide actions");
		}
		if(p.isAgent && !p.notErrand) {
			if (p.type != "CHAT") {
				showActions = <span className="errand-message-show-actions">
						{', '}
						<span onClick={this.handleShowActions} data-qa-id={"QA_msgAction_showActHistory"}>{showActionsTitle}</span>
					</span>;
			} else {
				showActions = null;
			}
			return <span className={className}>
					{errandNumber}{timeTxt}{timeComp}
					{toText}{toAgent}
					{showActions}
				</span>;
		}
		if(p.isAgent == false && p.type != "CHAT" && typeof p.hasAnswer !==
			'undefined' && p.hasAnswer == false){
				showActions = <span>{', '}
					<span style={{textDecoration: "underline"}}
					className="errand-message-show-actions">
						<span onClick={this.handleShowActions} data-qa-id={"QA_msgAction_showActHistory"}>{showActionsTitle}</span>
					</span>
				</span>;
		}
		let channel = null;
		if (p.channel) {
			channel = <ChannelText text={p.channel} via />;
		}

		return (
			<span className={className}>
				<ChannelIcon channelId={channelId} channelIcon={channelIcon ? channelIcon : ""}/>
				{errandNumber}{timeTxt}{timeComp}{p.tick}{channel}{showActions}{toText}{toAgent}
			</span>
		);
	}
}

class MessageTag extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			showTags: false
		}
	}
	render(){
		const p = this.props;
		let tagCount = 0, tagsInfo = [];
		if(typeof p.tags !== "undefined"){
			tagCount = p.tags.length;
			$.each(p.tags, function(i,v) {
				tagsInfo.push(<li key={i}><span>{v.desc}</span></li>);
			});
		}
		return (
			<div className="errand-message-tags" hidden={tagCount > 0 ? false: true} data-tooltip>
				<i className="icon-tag"></i><span className="errand-message-tags-counter">({tagCount})</span>
				<div className="errand-message-tags-info tooltip-content text-center">
					<ul>
						{tagsInfo}
					</ul>
				</div>
			</div>
			)
	}
}

class MessageRecipient extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const { list } = this.props;
		if(typeof list !== "undefined" && list !== {}){
			let mailsTo = [], mailsCc = [], mailsBcc = [], mailsAgents = [];
			if(list.to) {
				let to = list.to.split(",");
				$.each(to, function(i,v) {
					if(i == 0) {
						mailsTo.push(<li key={"collab-to-"+i} className="recipients-tags"><span className="label">{I("TO: ")}</span><span>{v}</span></li>);
					}
					else{
						mailsTo.push(<li key={"collab-to-"+i} className="recipients-tags"><span>{v}</span></li>);
					}
				});
				if(list.cc) {
					let cc = list.cc.split(",");
					$.each(cc, function(i,v) {
						if(i == 0) {
							mailsCc.push(<li key={"collab-cc-"+i} className="recipients-tags"><span className="label">{I("CC: ")}</span><span>{v}</span></li>);
						}else{
							mailsCc.push(<li key={"collab-cc-"+i} className="recipients-tags"><span>{v}</span></li>);
						}
					});
				}
				if(list.bcc) {
					let bcc = list.bcc.split(",");
					$.each(bcc, function(i,v) {
						if(i == 0) {
							mailsCc.push(<li key={"collab-bcc-"+i} className="recipients-tags"><span className="label">{I("BCC: ")}</span><span>{v}</span></li>);
						}else{
							mailsBcc.push(<li key={"collab-bcc-"+i} className="recipients-tags"><span>{v}</span></li>);
						}
					});
				}
			}
			if(list.toAgents) {
				let toAgents = list.toAgents.split(",");
				$.each(toAgents, function(i,v) {
						if(i == 0) {
							mailsAgents.push(<li key={"collab-agent-"+i} className="recipients-tags"><span className="label">{I("TO AGENTS: ")}</span><span>{v}</span></li>);
						}else{
							mailsAgents.push(<li key={"collab-agent-"+i} className="recipients-tags"><span>{v}</span></li>);
						}
					});
			}
			return (
				<div className="errand-message-tags recipients" data-tooltip data-qa-id="collab-recipients-tooltip">
					<i className="fas fa-users" style={{color: "#0c87f7"}}></i>
					<div className="errand-message-tags-info tooltip-content text-center">
						<ul>
							{
								//TODO: Show Target Area for Area collaboration
							}
							{mailsTo}
							{mailsCc}
							{mailsBcc}
							{mailsAgents}
						</ul>
					</div>
				</div>
				)
		}else{
			return null;
		}
	}
}

const MetaExtra = ({list}) => {
	if(!list || !list.length) {
		return null;
	}
	let dom = [];
	$.each(list, (i,v) => {
		dom.push(<span key={i}>{v}</span>);
	});
	return <span className="meta-extra">{dom}</span>;
};

class MessageMeta extends React.PureComponent {
	constructor(props) {
		super(props);
		this.loadActionsHistory = this.loadActionsHistory.bind(this);
		this.toggleActionsHistory = this.toggleActionsHistory.bind(this);
		this.state = {
			showActions: false
			, actionsHistorySizes: 0
		}
	}
	toggleActionsHistory(toggle) {
		this.setState({showActions: toggle});
	}
	loadActionsHistory() {
		let historyActions = [];
		$.each(this.props.actionsHistory, function(i,v) {
			historyActions.push(<div key={v.id} className="actions-history-info">
				<div>
					<span style={{color: v.color}}>
						<span>
							<i className={ACTIONS_HISTORY_ICONS[v.type]}></i>
						</span> {v.datetime} - {v.notes}
					</span>
				</div>
			</div>);
		});
		return <Measure
			bounds
			onResize={contentRect => {
				this.setState({ actionsHistorySizes: contentRect.bounds })
			}}>
			{({ measureRef }) => (
				<div ref={measureRef} className="errand-message-actions-history" hidden={!this.state.showActions}>{historyActions}</div>
				)}
		</Measure>
	}
	render() {
		const {
			extraMeta,
			isAgent,
			isAI,
			time,
			channel,
			status,
			error,
			noTZParse,
			parseFormat,
			notErrand,
			type,
			tags,
			actionsHistory,
			myErrandId,
			showErrandNumber,
			msg,
			hid,
			onActionClick,
			tick,
			hasAnswer,
			toText,
			toAgent,
			displayId,
			recipients,
			channelId,
			messageId,
			onEmoticonClick,
			channelIcon,
			...props
		} = this.props;
		const footnotes = (
				<MetaFootnotes
					hid={hid}
					isAgent={isAgent}
					time={time}
					error={error}
					channel={channel}
					channelId={channelId}
					status={status}
					noTZParse={noTZParse}
					parseFormat={parseFormat}
					notErrand={notErrand}
					type={type}
					onShowActions={this.toggleActionsHistory}
					tick={tick}
					hasAnswer={hasAnswer}
					toText={toText}
					toAgent={toAgent}
					displayId={displayId}
					showExactDayAndTime={props.showExactDayAndTime}
					showErrandNumber={showErrandNumber}
					channelIcon={channelIcon}
				/>
			)
			, actions = (
				<MetaActions
					isAgent={isAgent}
					myErrandId={myErrandId}
					msg={msg}
					onActionClick={onActionClick}
					type={type}
					{...props}
				/>
			)
			;
			let emoticons = null;
			if(notErrand){
				if(channelId === Workflow.Errand.SERVICE_FACEBOOK){
					emoticons = <MetaEmoticons messageId={messageId} channelId={channelId} onEmoticonClick={onEmoticonClick}/>;
				}
			}

		let msgClass = (type == 'INTERNALCHAT') ? 'message-meta' : 'errand-message-meta', metaHeight = 0;
		if(typeof this.state.actionsHistorySizes.height !== "undefined") {
			metaHeight = this.state.actionsHistorySizes.height + 10;
		}
		if(isAgent) {
			return <div className={msgClass} style={{marginBottom: metaHeight}}>
					<MetaExtra list={extraMeta} />
					{actions}{footnotes}
					<MessageTag tags={tags} hid={hid} />
					<MessageRecipient list={recipients}/>
					{this.props.actionsHistory && this.props.actionsHistory.length > 0 &&
						this.loadActionsHistory()
					}
				</div>;
		}
		return <div className={msgClass} style={{marginBottom: metaHeight}}>
				{footnotes}{actions}
				<MessageTag tags={tags} hid={hid} />
				<MessageRecipient list={recipients} />
				{this.props.actionsHistory && this.props.actionsHistory.length > 0 &&
					this.loadActionsHistory()
				}
			</div>;
	}
}

class MetaEmoticons extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			showEmoticons: false
		}
		this.handleToggleEmoticons = this.handleToggleEmoticons.bind(this);
		this.handleEmoticon = this.handleEmoticon.bind(this);
	}
	handleToggleEmoticons(){
		this.setState({showEmoticons: !this.state.showEmoticons})
	}
	handleEmoticon(e) {
		this.props.onEmoticonClick(e.currentTarget.dataset.id, this.props.messageId, this.props.channelId);
	}
	render(){
		return (
			<ul className="emoticon-trigger">
				<li key={'icon-spread'} onClick={this.handleToggleEmoticons} data-qa-id={"QA_msgAction_showEmoticons"}>
					<p className="emoticon-trigger-icon">
						<i className="far fa-smile" data-qa-id={"QA_msgAction_showEmoticons_icon"}></i>
					</p>
					<div className="emoticon-modal-container" hidden={!this.state.showEmoticons}>
						<ul className="emoticon-list">
							<li onClick={this.handleEmoticon} data-id={MESSENGER_ICON_DISLIKE}><span className="fb-icon-like" title="Dislike"></span></li>
							<li onClick={this.handleEmoticon} data-id={MESSENGER_ICON_LIKE}><span className="fb-icon-like" title="Like"></span></li>
							<li onClick={this.handleEmoticon} data-id={MESSENGER_ICON_LOVE}><span className="fb-icon-love" title="Love"></span></li>
							<li onClick={this.handleEmoticon} data-id={MESSENGER_ICON_SMILE}><span className="fb-icon-haha" title="Smile"></span></li>
							<li onClick={this.handleEmoticon} data-id={MESSENGER_ICON_WOW}><span className="fb-icon-wow" title="Wow"></span></li>
							<li onClick={this.handleEmoticon} data-id={MESSENGER_ICON_SAD}><span className="fb-icon-sad" title="Sad"></span></li>
							<li onClick={this.handleEmoticon} data-id={MESSENGER_ICON_ANGRY}><span className="fb-icon-angry" title="Angry"></span></li>
						</ul>
					</div>
				</li>
			</ul>
		)
	}
}

const MessageAttachments = ({list, type, ...props}) => {
	if(!list || !list.length) {
		return null;
	}
	return <div className="errand-message-attachment">
			<AttachmentTagList type={type} list={list} {...props} />
		</div>;
};

class MessageInfo extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const p = this.props;
		let errandClass = p.internalChat ? "" : "errand-";
		return (
			<div className={errandClass + "message-info"}>
				{p.isAI ? <span><i className="icon-chatbot"></i></span>
					:
					<ProfilePhoto width={"70"} isAgent={p.isAgent} className={errandClass + "message-photo"} photo={p.photo} />}
			</div>
		);
	}
}

export class Message extends React.PureComponent {
	constructor(props) {
		super(props);
		this.createMarkup = this.createMarkup.bind(this);
		this.handleActionClick = this.handleActionClick.bind(this);
		this.handleForwardCheckbox = this.handleForwardCheckbox.bind(this);
		this.handleAttachmentClick = this.handleAttachmentClick.bind(this);
		this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);
		this.handleAttachmentSelect = this.handleAttachmentSelect.bind(this);
		this.handleTranslation = this.handleTranslation.bind(this);
		this.state = {
			html: ""
		}
	}
	createMarkupText() {
		const p = this.props;
		const s = this.state;
		let html;
		if(typeof p.html !== 'undefined') {
			html = linkifyUrls(p.html);
		}
		return s.html ? s.html : html || p.html;
	}
	createMarkup() {
		return {__html: this.createMarkupText()};
	}
	handleAttachmentClick(type, id) {
		const p = this.props;
		if(p.onAttachmentClick) {
			p.onAttachmentClick(type, p.me, id, p.eid);
		}
	}
	handleAttachmentDelete(type, id, index, e) {
		const p = this.props;
		p.onAttachmentDelete(type, p.me, id, p.eid, index, e);
	}
	handleAttachmentSelect(type, id, index, e) {
		const p = this.props;
		p.onAttachmentSelect(type, p.me, id, p.eid, index, e);
	}
	handleActionClick(action) {
		const p = this.props;
		if(p.onActionClick){
			if(p.myErrandId || p.isChat || (action && action.toLowerCase() == "clone")) {
				p.onActionClick(action, p.myErrandId, p.html, p.isAgent);
			}else{
				p.onActionClick(action, p.me, p.eid, p.isAgent);
			}
		}
	}
	handleForwardCheckbox(v) {
		this.props.onSelectForward(this.props.eid, this.props.myErrandId, v);
	}
	handleTranslation(html) {
		if(this.refs.translateNotes){
			this.setState({html: html});
		}
	}
	render() {
		const {
			isAgent: agent,
			attachments,
			attachmentType,
			eid,
			hid,
			innerRef,
			forward,
			html = "",
			me,
			myErrandId,
			name,
			agentName,
			notErrand,
			photo,
			tags,
			actionsHistory,
			selected,
			showAttachmentSize,
			showDeleteAttachment,
			showSelectAttachment,
			onActionClick,
			onAttachmentClick,
			onAttachmentDelete,
			onAttachmentSelect,
			onSelectForward,
			parseFormat,
			error,
			type,
			canTranslate,
			detectLangOnly,
			onTranslation,
			interfaceLang,
			toLang,
			fromLang,
			fromCust,
			truncateText,
			truncateErrandMessageByDefault,
			onSetErrandMessageTruncatePref,
			onClickAgentAssistTrigger,
			onSend,
			toText,
			toAgent,
			statusId,
			channelId,
			socialEmoticon,
			onSocialMediaUIAction,
			recipients,
			"data-qa-id": dataQAId,
			isCurrentOpen,
			messageId,
			onClickEmoticon,
			channelIcon,
			fromAI,
			area,
			currentSuggestedAnswerUsed,
			...props // NOTE: this spread props so message actions can has any properties
		} = this.props;
		let internalChat = type == 'INTERNALCHAT';
		let errandClass = internalChat ? '' : 'errand-';
		let errandContentClass = errandClass + 'message-content';
		let errandContentElemId = "";
		let currentErrandElemId = "";
		if(hid){
			errandContentElemId = "errand-history-"+hid;
		}
		let msgClass = errandClass + 'message';
		let textBoxClass = errandClass + "message-textbox";
		let translateNotes="";
		let msgType = "errand";
		let msgFrom = "agent";
		if(!notErrand) {
			msgClass = 'msg ' + msgClass;
		}
		if(agent) {
			if (this.props.fromOtherAgent) {
				msgClass += ' otheragent';
			} else {
				msgClass += ' my';
			}
		}
		if(props.breakLine) {
			msgClass += ' current';
			if(props.displayId) {
				currentErrandElemId = 'errand-history-' + props.displayId;
			}
		}
		if(isCurrentOpen) {
			msgClass += ' currentOpen'
		}
		if(canTranslate && html != "") {
			let lang = "";
			if(fromLang) {
				lang = fromLang;
			}else {
				lang = fromCust;
			}
			translateNotes = <TranslateNotes ref="translateNotes" html={html} type={type}
				onTranslation={onTranslation}
				canTranslate={canTranslate}
				detectLangOnly={detectLangOnly}
				interfaceLang={interfaceLang}
				toLang={toLang}
				fromLang={lang}
				isAgent={this.props.isAgent}
				translateData={this.handleTranslation} />
		}
		if(this.props.isAgent == false && this.props.channel === "Manual"){
			if(html == ""){
				textBoxClass += "-invalid-message";
			}
		}
		if(type === MT_NOTE){
			msgType = "comment";
		}else if(type === MT_COLLABORATION){
			msgType = "collab";
			if(!agent){
				msgFrom = "ee";
			}
		}else{
			if(!agent){
				msgFrom = "client";
			}
		}
		let senderName = name;
		if(!name && !fromCust) {
			senderName = agentName;
		}
		let emptyAnswer = false, currentAnswerHistoryFromAI = false;
		if(html && html.replace(/<[^>]*>/g, '').trim() === '' || html.trim() == '') {
			emptyAnswer = true;
		}
		if (cflag.IsActive("2024-05-15.CEN-2403.use.llm.for.auto.answer.errand")) {
			if (features["machine-learning.auto-answer"] || (features["amazon-comprehend"] && features["machine-learning.suggest-answer"])) {
				if(cflag.IsActive("2024-08-13.CEN-2536.auto.answer.gui") ) {
					if(typeof hid === "undefined") {
						if(currentSuggestedAnswerUsed) {
							currentAnswerHistoryFromAI = true;
						}
					}
					// TODO: this feature is not yet implemented
					/* if((fromAI || currentAnswerHistoryFromAI) && !emptyAnswer) {
						msgClass += " automatedAnswer";
						if (features["machine-learning.automatic-send-answer"]) {
							senderName = I("Automated Answer");
						}
					} */
				}
			}
		}
		return (
			<div id={currentErrandElemId} className={msgClass} ref={innerRef}>
				<div className={errandClass + "message-inner"}>
					{!internalChat || (internalChat && !agent) ? <MessageInfo photo={photo} isAI={(fromAI || currentAnswerHistoryFromAI) && !emptyAnswer} isAgent={agent} internalChat={internalChat}/> : null}
					<div id={errandContentElemId} className={errandContentClass}>
						<div className={textBoxClass} data-qa-id={"msg-text-box-"+msgType+"-"+msgFrom}>
							<div className="checkbox-wrapper" style={{display: 'block'}}>
								{
									//avoid showing checkbox and name when message is empty
									this.createMarkupText() &&
										<React.Fragment>
											<ConversationCheckbox
												checked={selected ? true : false}
												show={forward}
												onClick={this.handleForwardCheckbox}
											/>
											<div className={errandClass + "message-name"} title={senderName}>{senderName}</div>
										</React.Fragment>
								}
								<TextTruncateAndExternalLink
									data-qa-id={dataQAId}
									forwardCheckboxesShown={forward}
									isChat={type === "CHAT"}
									truncateErrandMessageByDefault={truncateErrandMessageByDefault}
									truncateText={truncateText}
									onSetErrandMessageTruncatePref={onSetErrandMessageTruncatePref}
									onClickAgentAssistTrigger={onClickAgentAssistTrigger}
									onSend={onSend}
									myErrandId={myErrandId}
									area={area}
									limit={DEFAULT_ERRAND_LENGTH_LIMIT}
									showMore={true}
									text={this.createMarkupText()}
								/>
							</div>
							<SocialEmoticon
								status={statusId}
								channelId={channelId}
								emotions={socialEmoticon}
								myErrandId={myErrandId}
								eid={eid}
								notErrand={notErrand}
								onSocialMediaUIAction={onSocialMediaUIAction}
							/>
							{translateNotes}
							<MessageAttachments data-qa-id={"msg-files-"+dataQAId} list={attachments}
								type={attachmentType}
								preview={!!onAttachmentClick}
								showDelete={showDeleteAttachment}
								showSelect={showSelectAttachment}
								showSize={showAttachmentSize}
								onClick={this.handleAttachmentClick}
								onDelete={this.handleAttachmentDelete}
								onSelect={this.handleAttachmentSelect} />
						</div>
						<MessageMeta
							isAgent={agent}
							isAI={fromAI || currentAnswerHistoryFromAI}
							parseFormat={parseFormat}
							notErrand={notErrand}
							hid={hid}
							myErrandId={myErrandId}
							msg={html}
							tags={tags}
							actionsHistory={actionsHistory}
							type={type}
							error={error}
							tick={props.tick}
							onActionClick={this.handleActionClick}
							toText={toText}
							toAgent={toAgent}
							showExactDayAndTime={props.showExactDayAndTime}
							recipients={recipients}
							channelId={channelId}
							messageId={messageId}
							onEmoticonClick={onClickEmoticon}
							channelIcon={channelIcon}
							{...props}
						/>
					</div>
				</div>
			</div>
		);
	}
}

class SocialEmoticon extends React.Component{
	handleOnClick = () =>{
		let currentEmotErrand = this.props.eid;
		if(currentEmotErrand !== this.props.myErrandId){
			currentEmotErrand = this.props.myErrandId;
		}
		this.props.onSocialMediaUIAction(currentEmotErrand, this.props.status);
	}
	render(){
		const {
			channelId,
			status,
			emotions,
			notErrand
		} = this.props;
		let hid = false;
		if(status === SENT || status === CLOSED) {
			if(channelId === Workflow.Errand.SERVICE_FACEBOOK ||channelId === Workflow.Errand.SERVICE_INSTAGRAM || channelId === Workflow.Errand.SERVICE_MANUAL_FACEBOOK){
				hid = true;
			}
		}
		if(notErrand){
			//if fb chat
			if(channelId === Workflow.Errand.SERVICE_FACEBOOK||channelId === Workflow.Errand.SERVICE_INSTAGRAM){
				hid = true;
			}
		}
		if(!hid) {
			return null;
		}
		else {
			let socialEmots = [], counts = 0;
			if(typeof emotions === 'undefined') return null;
			if(emotions !== null && emotions.length > 0) {
				emotions.forEach(function (v, i) {
					for (let prop in v) {
						if (v.hasOwnProperty(prop)) {
							socialEmots.push(<span key={'icons-' + prop} className={"" + prop}></span>);
							counts += parseInt(v[prop]);
						}
					}
				});
			}
			if(counts <= 0)	return null;

			return (<a className={"emoticons"} href="#" onClick={this.handleOnClick}>{socialEmots} <span className={"emoticonCount"}>{counts}</span></a>);
		}
	}
}

/* New msg component for Right Panel  */
/* TODO: Enhancement to make it general to be used for collaboration */
export class PanelSingleMessage extends PureComponent {
	constructor(props) {
		super(props);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleActionClick = this.handleActionClick.bind(this);
	}
	handleEdit() {
		this.props.onEditNoteClick(this.props.me, this.props.eid);
	}
	handleActionClick(action){
		this.props.onActionClick(action, this.props.me, this.props.eid);
	}
	handleAttachmentSelect = (type, id, index, e) => {
		const p = this.props;
		p.onAttachmentSelect(type, p.me, id, p.eid, index, e);
	}
	render() {
		const {isAgent
			, html
			, type
			, onActionClick
			, className
			, recipients
			, saved
			, ...p
		} = this.props;

		let savedTxt, queryId = ''
		let thisId = "";
		let tagCount = 0, tagsInfo = [];
		if (typeof p.tagList !== "undefined") {
			const filteredTags = p.tagList.filter(tag => tag.desc.startsWith("Collab_"));
			tagCount = filteredTags.length;
			$.each(filteredTags, function(i, v) {
				tagsInfo.push(<li key={i}><span>{v.desc}</span></li>);
			});
		}
		if (p.queryId) {
			queryId = '#'+p.queryId
			thisId = className+ "-" + p.queryId
		} else {
			thisId = className+ "-" + getRandomString(5);
		}
		if (saved) {
			savedTxt = "**Saved"
		}
		return <div className={classNames("icPanelMsg", className)} id={thisId}>
			<div className={"icPanelInfo"}>
				<div className="icPanelPhoto">
					<ProfilePhoto width={"50"} isAgent={true}
						photo={p.photo}
					/>
				</div>
				<div className="icPanelNameWrapper">
					{
						type === MT_COLLABORATION && queryId != '' &&
					 	<span className="thread-id">{queryId}</span>
					}
					<span className="icPanelName">{p.name}</span>
					<div className="icPanelTime">{p.time}</div>
				</div>
				{
					type === MT_NOTE && !p.lockEditErrandNote &&
						<div className="icPanelEdit">
							<span className={classNames("icBtnEdit", {"active" : p.currentEditNote === p.me})} onClick={this.handleEdit}>
								<i className="icon-pencil" />{I("Edit")}
							</span>
						</div>
				}
				{/* {
					type === MT_COLLABORATION &&
						<div className="icPanelEdit collab">
							<span className={classNames("icBtnEdit", {"active" : p.currentEditNote === p.me})} onClick={this.handleEdit}>
								<i className="icon-pencil" />{I("REPLY")}
							</span>
						</div>
				} */}
			</div>
			<TextTruncateAndExternalLink
				data-qa-id={"internalCommentMsg"+p.me}
				text={html}
				limit={DEFAULT_ERRAND_LENGTH_LIMIT}
			/>
			<MessageAttachments data-qa-id={"msg-files-"+p.me} list={p.attachments}
				type={p.attachmentType}
				showDelete={false}
				showSelect={false}
				showSize={false}
				onSelect={this.handleAttachmentSelect}
			/>
			<div className="meta-wrapper">
				<span className="icPanelSaved">{savedTxt}</span>
				<MetaActions
					isAgent={isAgent}
					msg={html}
					type={type}
					showAll={false}
					showSize={0}
					onActionClick={this.handleActionClick}
					{...p}
				/>
				{type === MT_COLLABORATION && <MessageRecipient list={recipients} tagList={p.tagList}/>}
				<div className="errand-message-tag"  data-tooltip>
				<i className="icon-tag"></i><span className="errand-message-tags-counter">({tagCount})</span>
				<div className="errand-message-tags-info tooltip-content text-center">
					<ul>
						{tagsInfo}
					</ul>
				</div>
			</div>
			</div>
		</div>
	}
}

export class RightBarMsgPanel extends React.Component {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
		this.handleActionClick = this.handleActionClick.bind(this);
	}
	handleClose() {
		this.props.onClose(false);
	}
	handleActionClick(act, note, errand) {
		if(this.props.msgType === MT_NOTE) {
			switch(act) {
				case 'edit':
					this.props.onEditNoteClick(note, errand);
					break;
				case 'delete':
					this.props.onDeleteNoteClick(note, errand);
					break;
			}
		}
	}
	render() {
		const {
			customStyle,
			notes,
			eid,
			currentEditNote,
			lockEditErrandNote,
			onEditNoteClick,
			onDeleteNoteClick
		} = this.props;
		let m = [];
		if(typeof notes !== "undefined" && notes.length > 0) {
			for(var note of notes){
				m.push(<PanelSingleMessage key={'note'+eid+note.id}
					eid={eid}
					me={note.id}
					type={this.props.msgType}
					photo={note.avatar} name={note.username} time={note.writtenDate}
					showAll={false} showSize={0} isAgent={true} currentEditNote={currentEditNote}
					edit={lockEditErrandNote?false:true}
					delete={lockEditErrandNote?false:true}
					html={note.note}
					lockEditErrandNote={lockEditErrandNote}
					onEditNoteClick={onEditNoteClick}
					onDeleteNoteClick={onDeleteNoteClick}
					attachmentType={this.props.AT_SAVED}
					attachments={note.attachments}
					onActionClick={this.handleActionClick}
					/>
				);
			}
		}
		return (
			<ChatPanelWrapper>
				<div className="icPanelHeader">
					<span className="icPanelHeaderClose" onClick={this.handleClose}><i className="icon-times"/></span>
					{I("Internal Comments")}
				</div>
				<div className="icPanelBody"
					style={customStyle}
					>
					{m}
				</div>
			</ChatPanelWrapper>
		)
	}
}
